import { FC } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { Apartment, ArticleVariant } from "models";
import { Article, NavigationBar, Footer } from "components";
import {
  MANO_NAVIGATION_LINKS,
  TRAVERTIN_NAVIGATION_LINKS,
} from "constants/links";

interface PageWrapperProps {
  apartment: Apartment | undefined;
  useTransparentNavbar?: boolean;
  fullWidthPage?: boolean;
  hasContactArticle?: boolean;
}

export const PageWrapper: FC<PageWrapperProps> = ({
  apartment,
  useTransparentNavbar,
  fullWidthPage,
  hasContactArticle,
  children,
}) => {
  const { t } = useTranslation();

  const links = {
    MANO: MANO_NAVIGATION_LINKS,
    TRAVERTIN: TRAVERTIN_NAVIGATION_LINKS,
  };

  const addresses = {
    MANO: "MANO.LOCATION",
    TRAVERTIN: "TRAVERTIN.LOCATION",
  };

  const backgrounds = {
    MANO: "bg-mano6",
    TRAVERTIN: "bg-travertin3",
  };

  return (
    <div
      className={classNames(
        "flex flex-col items-center relative overflow-hidden",
        fullWidthPage ? "w-full" : "m-w-7xl"
      )}
    >
      <NavigationBar
        logoText={`${apartment}.NAME`}
        links={apartment ? links[apartment] : []}
        useTransparentNavbar={useTransparentNavbar}
      />
      {children}
      {hasContactArticle && (
        <Article
          variant={ArticleVariant.HIGHLIGHT}
          title={"COMMON.NEEDHELP.TITLE"}
          className={classNames(
            "py-14 mb-0 text-white w-full",
            apartment ? backgrounds[apartment] : ""
          )}
          link={{
            label: "COMMON.NEEDHELP.LINK_LABEL",
            href: `/${apartment?.toLowerCase()}/contact`,
          }}
          darkBackground
        />
      )}
      <Footer
        apartment={apartment}
        logoText={`${apartment}.NAME`}
        links={apartment ? links[apartment] : []}
        email={t("COMMON.CONTACTS.EMAIL")}
        telephone={t("COMMON.CONTACTS.PHONE")}
        address={apartment ? t(`${addresses[apartment]}`) : ""}
      />
    </div>
  );
};
