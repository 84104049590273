import React from "react";
import { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import { AppLoader } from "components";

import App from "./App";
import "./i18n";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Suspense
        fallback={
          <div className="bg-mano4 flex justify-center items-center h-screen w-screen">
            <AppLoader />
          </div>
        }
      >
        <App />
      </Suspense>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
