export enum TravelMethod {
  car = "car",
  walk = "walk",
}

export interface DetailedListItem {
  label: string;
  value: string | number;
  value2?: string | number;
  unit: string;
}

export interface DetailedListColumn {
  title: string;
  items: DetailedListItem[];
}
