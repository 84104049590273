import { FC, useState, useEffect, Fragment, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";
import { animateScroll as scroll } from "react-scroll";
import i18n from "i18n";
import { Box } from "@mui/system";
import {
  Divider,
  Drawer,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";
import ReactCountryFlag from "react-country-flag";
import { uniqueId } from "lodash";
import disableScroll from "disable-scroll";
import { motion, AnimatePresence } from "framer-motion";

import { useWindowResize, useScroll } from "components";
import { shortFadeInAnimation, fadeInAnimation } from "constants/animate";

import "./NavigationBar.overrides.scss";

export interface NavigationLink {
  route: string;
  label: string;
  icon: ReactElement;
}

export interface NavigationBarProps {
  logoText: string;
  links: NavigationLink[];
  useTransparentNavbar?: boolean;
}

export const NavigationBar: FC<NavigationBarProps> = ({
  logoText,
  links,
  useTransparentNavbar,
}) => {
  const { t } = useTranslation();
  const { ref, controls } = useScroll(0);
  const sizeInfo = useWindowResize();
  const location = useLocation();

  const [isOpen, setOpen] = useState<boolean>(false);
  const [isTransparent, setTransparent] = useState<boolean>(
    useTransparentNavbar || false
  );

  const isMano = location.pathname.includes("/mano");
  const isTravertin = location.pathname.includes("/travertin");
  const TRANSPARENT_CLASSNAMES = `bg-transparent text-white TransparentNavbar`;
  const WHITE_CLASSNAMES = `bg-white WhiteNavbar text-mano4 ${
    isMano ? "text-mano4" : "text-travertin5"
  }`;

  useEffect(() => {
    if (useTransparentNavbar) {
      const handleScroll = (e: any) => {
        if (window.scrollY === 0) {
          setTransparent(true);
          return;
        }
        setTransparent(false);
      };

      window.addEventListener("scroll", handleScroll, true);

      return () => {
        window.removeEventListener("scroll", handleScroll, true);
      };
    }
  }, [useTransparentNavbar]);

  useEffect(() => {
    if (isOpen && sizeInfo.underMd) {
      disableScroll.on();
      return;
    }
    if (!sizeInfo.underMd) {
      setOpen(false);
    }
    disableScroll.off();
  }, [isOpen, sizeInfo]);

  const changeLanguage = () =>
    i18n.changeLanguage(i18n.language === "hu" ? "en" : "hu");

  const getLogoLink = () => {
    if (isMano) return "/mano";
    if (isTravertin) return "/travertin";
    return "/";
  };

  const logo = (
    <motion.div
      variants={shortFadeInAnimation}
      className={"py-3 text-3xl font-bold w-96"}
    >
      <Link to={getLogoLink()}>{t(logoText)}</Link>
    </motion.div>
  );

  const navLinks = links.map((link) => {
    return (
      <Link
        to={link.route}
        className="px-2 text-lg text-base"
        key={uniqueId()}
        onClick={() => {
          if (link.route === window.location.pathname) {
            scroll.scrollToTop({ duration: 400 });
          }
        }}
      >
        {t(link.label)}
      </Link>
    );
  });

  const countryFlag = (
    <motion.div variants={sizeInfo.underMd ? undefined : shortFadeInAnimation}>
      <Tooltip title={t("NAVIGATION.LANGUAGE_TOOLTIP").toString()}>
        <Box
          className={classNames(
            "md:ml-4 lg:border-0",
            { "md:border-l": links.length },
            isTransparent ? "border-white" : "border-mano4"
          )}
        >
          <ReactCountryFlag
            countryCode={i18n.language === "hu" ? "GB" : "HU"}
            className="m-0 md:ml-5 py-1 text-3xl leading-6 cursor-pointer rounded-lg"
            svg
            onClick={() => {
              i18n.changeLanguage(i18n.language === "hu" ? "en" : "hu");
            }}
          />
        </Box>
      </Tooltip>
    </motion.div>
  );

  const socialsWithBookButton = (
    <div className="socials py-3 flex items-center justify-end w-96">
      {countryFlag}
    </div>
  );

  const mobileMenuIcon = (
    <div
      className={"cursor-pointer"}
      onClick={() => {
        setOpen(!isOpen);
        setTransparent(false);
      }}
    >
      <div
        className={classNames(
          "MenuIconLine",
          { "bg-white": isTransparent },
          { "bg-mano4": !isTransparent && isMano },
          { "bg-travertin5": !isTransparent && !isMano }
        )}
        style={{
          transform: isOpen ? "rotate(45deg) translateY(13px)" : "rotate(0deg)",
        }}
      />
      <div
        className={classNames(
          "MenuIconLine",
          { "bg-white": isTransparent },
          { "bg-mano4": !isTransparent && isMano },
          { "bg-travertin5": !isTransparent && !isMano }
        )}
        style={{ opacity: isOpen ? 0 : 1 }}
      />
      <div
        className={classNames(
          "MenuIconLine",
          { "bg-white": isTransparent },
          { "bg-mano4": !isTransparent && isMano },
          { "bg-travertin5": !isTransparent && !isMano }
        )}
        style={{
          transform: isOpen
            ? "rotate(-45deg) translateY(-13px)"
            : "rotate(0deg)",
        }}
      />
    </div>
  );

  const mobileDropdownMenu = (
    <Fragment>
      {mobileMenuIcon}
      <Drawer
        className="NavigationDrawer relative"
        anchor={"top"}
        open={isOpen}
        onClose={() => setOpen(!isOpen)}
      >
        {links.map((link) => {
          const isCurrentPage = location.pathname === link.route;
          console.log(isCurrentPage && location.pathname, link.route);
          return (
            <Link to={link.route}>
              <ListItem button key={link.route}>
                <ListItemIcon>{link.icon}</ListItemIcon>
                <ListItemText
                  primary={t(link.label)}
                  primaryTypographyProps={{
                    fontWeight: isCurrentPage ? 500 : 400,
                  }}
                />
              </ListItem>
              <Divider variant="inset" />
            </Link>
          );
        })}
        <ListItem button key={"changeLanguage"} onClick={changeLanguage}>
          <ListItemIcon>{countryFlag}</ListItemIcon>
          <ListItemText primary={t("NAVIGATION.LANGUAGE_TOOLTIP")} />
        </ListItem>
      </Drawer>
    </Fragment>
  );

  return (
    <>
      <div
        className={"relative w-full h-14 bg-white"}
        style={{
          height: document.getElementById("NavigationBar")?.clientHeight,
        }}
      ></div>
      <motion.div
        id="NavigationBar"
        className={classNames(
          "px-5 md:px-10 w-full flex items-center fixed top-0 z-10 justify-between lg:justify-center",
          isTransparent ? TRANSPARENT_CLASSNAMES : WHITE_CLASSNAMES
        )}
        ref={ref}
        initial={"hidden"}
        animate={controls}
      >
        {logo}
        {!sizeInfo.underMd && (
          <motion.nav
            className={
              "py-3 w-96 flex justify-end lg:justify-center items-center"
            }
            variants={shortFadeInAnimation}
          >
            {navLinks}
            {sizeInfo.underLg && countryFlag}
          </motion.nav>
        )}
        {!sizeInfo.underLg && socialsWithBookButton}
        {sizeInfo.underMd && mobileDropdownMenu}
      </motion.div>
    </>
  );
};
