import classNames from "classnames";
import { FC } from "react";

interface FooterColumnProps {
  large?: boolean;
}

export const FooterColumn: FC<FooterColumnProps> = ({ children, large }) => {
  return (
    <div
      className={classNames(
        "flex flex-col mb-10 pr-10",
        large ? "md:w-80" : "md:w-52"
      )}
    >
      {children}
    </div>
  );
};
