import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "i18n";
import { BsFillTelephoneFill } from "react-icons/bs";
import { MdAlternateEmail } from "react-icons/md";
import { GoLocation } from "react-icons/go";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import classNames from "classnames";
import { IconBaseProps } from "react-icons";
import { motion } from "framer-motion";

import {
  AppLoader,
  Article,
  ContactForm,
  PageWrapper,
  useScroll,
  useWindowResize,
} from "components";
import { colors } from "constants/colors";
import { Apartment, ArticleVariant } from "models";
import { delayedFadeInAnimation } from "constants/animate";

interface ContactPageProps {
  apartment: Apartment;
}

export const ContactPage: FC<ContactPageProps> = ({ apartment }) => {
  const { t } = useTranslation();
  const sizeInfo = useWindowResize();
  const { ref, controls } = useScroll(0.3);

  const [isMapsLoading, setMapsLoading] = useState(true);
  const [isFormLoading, setFormLoading] = useState(false);
  const [isSent, setSent] = useState(false);
  const [emailError, setEmailError] = useState(false);

  const googleMapsUrls = {
    MANO: `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2674.7670675183117!2d20.364299951806238!3d47.90219357540142!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47408da1d23fd91f%3A0x1d16182d15a5d1c9!2sMan%C3%B3%20Apartman!5e0!3m2!1shu!2shu!4v1646434113191!5m2!1s${i18n.language}!2s${i18n.language}`,
    TRAVERTIN: `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2674.6055481984877!2d20.374264717443843!3d47.905319500000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47408d9979d3e0bd%3A0xdfb23a610e09c07b!2sEger%2C%20Dayka%20G%C3%A1bor%20u.%204%2C%203300!5e0!3m2!1shu!2shu!4v1646921436130!5m2!1s${i18n.language}!2s${i18n.language}`,
  };

  const pageColors = {
    iconColors: {
      MANO: colors.mano4,
      TRAVERTIN: colors.travertin5,
    },
    textColors: {
      MANO: "text-mano4",
      TRAVERTIN: "text-travertin5",
    },
    backgroundColors: {
      MANO: "bg-mano4",
      TRAVERTIN: "bg-travertin4",
    },
    formBackgroundColors: {
      MANO: "bg-mano6",
      TRAVERTIN: "bg-travertin3",
    },
  };

  const commonIconProps: IconBaseProps = {
    size: 50,
    color: pageColors.iconColors[apartment],
    className: "mb-3",
  };

  const commonIconContainerClassNames =
    "flex flex-col items-center md:w-1/3 py-10 md:p-5";

  const contactOptions = (
    <motion.div
      className="flex flex-col md:flex-row justify-center items-center md:items-start w-full max-w-7xl px-5 md:px-10 md:mt-10 mb-10 md:mb-20 text-center"
      ref={ref}
      animate={controls}
      initial={"hidden"}
      variants={delayedFadeInAnimation}
    >
      <div className={commonIconContainerClassNames}>
        <BsFillTelephoneFill {...commonIconProps} />
        <p className={classNames("text-lg", pageColors.textColors[apartment])}>
          {t("COMMON.CONTACTS.PHONE")}
        </p>
      </div>
      <div
        className={classNames(
          "border-y-2 md:border-x-2 md:border-y-0 border-mano3",
          commonIconContainerClassNames
        )}
      >
        <MdAlternateEmail {...commonIconProps} />
        <p className={classNames("text-lg", pageColors.textColors[apartment])}>
          {t("COMMON.CONTACTS.EMAIL")}
        </p>
      </div>
      <div
        className={classNames("w-1/2 sm:w-1/3", commonIconContainerClassNames)}
      >
        <GoLocation {...commonIconProps} />
        <p className={classNames("text-lg", pageColors.textColors[apartment])}>
          {t(`${apartment}.LOCATION`)}
        </p>
      </div>
    </motion.div>
  );

  const getEmailTitle = () => {
    if (isSent && !emailError) return "COMMON.CONTACTS.FORM.SUCCESS.TITLE";
    if (!isSent && emailError) return "COMMON.CONTACTS.FORM.ERROR.TITLE";
    return "COMMON.NEEDHELP.TITLE";
  };

  const getEmailDescription = () => {
    if (isSent && !emailError)
      return "COMMON.CONTACTS.FORM.SUCCESS.DESCRIPTION";
    if (!isSent && emailError) return "COMMON.CONTACTS.FORM.ERROR.DESCRIPTION";
    return "COMMON.NEEDHELP.LINK_LABEL";
  };

  return (
    <PageWrapper apartment={apartment}>
      <Article
        variant={ArticleVariant.SEPARATED_ITEMS}
        title={"COMMON.CONTACT_DETAILS"}
        className={classNames("mt-20", pageColors.textColors[apartment])}
      >
        {contactOptions}
      </Article>
      <Article
        variant={ArticleVariant.HIGHLIGHT}
        inclinedTitle={t("COMMON.NAVIGATION.INCLINED_TITLE")}
        title={t("COMMON.NAVIGATION.TITLE")}
        description={t("COMMON.NAVIGATION.DESCRIPTION")}
        className={classNames(
          "py-20 w-full text-white",
          pageColors.backgroundColors[apartment]
        )}
        darkBackground
      />
      <div className="max-w-7xl w-full bg-white px-5 md:px-10 mb-40 relative">
        <iframe
          title="Google Maps"
          className={classNames(
            "w-full bg-blend-multiply",
            pageColors.backgroundColors[apartment]
          )}
          style={{ height: sizeInfo.underLg ? "300px" : "500px" }}
          scrolling="no"
          src={googleMapsUrls[apartment]}
          onLoad={() => setMapsLoading(false)}
        />
        {isMapsLoading && <AppLoader />}
      </div>
      <Article
        variant={ArticleVariant.HIGHLIGHT}
        title={isFormLoading ? "" : getEmailTitle()}
        description={isFormLoading ? "" : getEmailDescription()}
        className={classNames(
          "py-14 mb-0 pb-20 w-full flex-col items-center relative",
          pageColors.formBackgroundColors[apartment],
          { "py-80": isFormLoading }
        )}
        darkBackground
        childrenOnTop={isSent}
        childrenClassName={"w-full flex justify-center"}
      >
        {isSent && !emailError && (
          <CheckCircleIcon
            htmlColor="green"
            sx={{ fontSize: 128 }}
            className={"mt-5"}
          />
        )}
        {!isSent && emailError && (
          <ErrorOutlineIcon
            htmlColor="red"
            sx={{ fontSize: 128 }}
            className={"mt-5"}
          />
        )}
        {!isSent && !emailError && (
          <ContactForm
            apartment={apartment}
            setSent={setSent}
            setEmailError={setEmailError}
            isLoading={isFormLoading}
            setLoading={setFormLoading}
          />
        )}
      </Article>
    </PageWrapper>
  );
};
