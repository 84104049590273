import { FC } from "react";
import { useTranslation } from "react-i18next";
import { IoCashOutline } from "react-icons/io5";
import { FaRegCreditCard } from "react-icons/fa";
import { motion } from "framer-motion";

import {
  Article,
  CarouselWrapper,
  DetailedList,
  ImageWrapper,
  PageWrapper,
  PriceCalculator,
  useScroll,
  useWindowResize,
} from "components";
import { Apartment, ArticleVariant } from "models";
import {
  fadeInListAnimation,
  fadeInListItemAnimation,
} from "constants/animate";
import { colors } from "constants/colors";
import { TRAVERTIN_GALLERY_IMAGES } from "constants/imageLists";
import { TRAVERTIN_PRICES_LIST_ELEMENTS } from "constants/listItems";

import "./Prices.overrides.scss";
import { szepCardImageUrl } from "constants/links";

const Prices: FC = () => {
  const { t } = useTranslation();
  const { ref, controls } = useScroll(0.3);
  const sizeInfo = useWindowResize();

  const iconSize = sizeInfo.underMd ? 70 : 80;

  const SzepCardSlide = (
    <Article
      disableAnimation
      key={"SzepCardSlide"}
      variant={ArticleVariant.HIGHLIGHT}
      darkBackground
      title={"COMMON.PRICES.SZEPCARD.TITLE"}
      description={"COMMON.PRICES.SZEPCARD.DESCRIPTION"}
      className={"bg-travertin4 py-10 w-full text-white mb-0"}
      style={{
        maxHeight: !sizeInfo.underLg ? "550px" : "525px",
        height: !sizeInfo.underLg ? "550px" : "525px",
      }}
    >
      <div
        className="w-full flex justify-center mt-10"
        style={{ width: "90%", margin: "auto" }}
      >
        <ImageWrapper
          src={szepCardImageUrl}
          alt="SZEP Card image"
          width={500}
          disableAnimation
        />
      </div>
    </Article>
  );

  const AccomodationSlide = (
    <Article
      disableAnimation
      key={"AccomodationSlide"}
      variant={ArticleVariant.HEADER}
      title={"MANO.ACCOMODATIONS_PROMO_ARTICLE.TITLE"}
      description={"MANO.ACCOMODATIONS_PROMO_ARTICLE.DESCRIPTION"}
      className={
        "bg-travertin5 text-white min-h-full ManoPricesHeader justify-center"
      }
      style={{
        maxHeight: !sizeInfo.underLg ? "550px" : "",
        height: !sizeInfo.underLg ? "550px" : "",
      }}
      link={{ label: "COMMON.VIEW", href: "/" }}
      imageSrc={TRAVERTIN_GALLERY_IMAGES[0].src.replace(
        "{{transformation}}",
        ""
      )}
      darkBackground
    />
  );

  const WaysOfPayment = (
    <motion.div
      ref={ref}
      animate={controls}
      initial={"hidden"}
      variants={fadeInListAnimation}
      className="flex flex-col md:flex-row justify-center items-center md:items-start w-full max-w-7xl px-5 md:px-10 md:mt-10 mb-20 md:mb-32 text-center"
    >
      <motion.div
        className="flex flex-col items-center w-1/2 py-10 md:p-5"
        variants={fadeInListItemAnimation}
      >
        <IoCashOutline
          size={iconSize}
          color={colors.mano4}
          className={"mb-3"}
        />
        <p className="text-lg text-travertin5">
          {t("COMMON.WAYS_OF_PAYMENT.CASH")}
        </p>
      </motion.div>
      <motion.div
        className="flex flex-col items-center w-1/2 py-10 md:p-5 border-t-2 md:border-l-2 md:border-y-0 border-mano3"
        variants={fadeInListItemAnimation}
      >
        <FaRegCreditCard
          size={iconSize}
          color={colors.mano4}
          className={"mb-3"}
        />
        <p className="text-lg text-travertin5">
          {t("COMMON.WAYS_OF_PAYMENT.CARD")}
        </p>
      </motion.div>
    </motion.div>
  );

  return (
    <PageWrapper
      apartment={Apartment.TRAVERTIN}
      fullWidthPage
      hasContactArticle
    >
      <CarouselWrapper
        elements={[
          SzepCardSlide,
          AccomodationSlide,
          SzepCardSlide,
          AccomodationSlide,
        ]}
      />
      <Article title={"COMMON.PRICELIST"} className={"mt-20 mb-0"} />
      <DetailedList elements={TRAVERTIN_PRICES_LIST_ELEMENTS} />
      <Article
        variant={ArticleVariant.SEPARATED_ITEMS}
        title={"COMMON.WAYS_OF_PAYMENT.TITLE"}
      >
        {WaysOfPayment}
      </Article>
      <PriceCalculator apartment={Apartment.TRAVERTIN} />
    </PageWrapper>
  );
};

export default Prices;
