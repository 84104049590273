import { CSSProperties, FC } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";
import { motion } from "framer-motion";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

import { ImageWrapper, useScroll, useWindowResize } from "components";
import {
  delayedFadeInAnimation,
  fadeInAnimation,
  subTitleAnimation,
  subTitleAnimationRight,
  titleAnimationLeft,
  titleAnimationRight,
} from "constants/animate";
import {
  descriptionClass,
  inclinedTitleClass,
  titleClass,
} from "constants/classNames";
import { ArticleVariant, LinkProps } from "models";

import classes from "./Article.module.scss";

interface ArticleProps {
  inclinedTitle?: string;
  title: string;
  style?: CSSProperties;
  variant?: ArticleVariant;
  description?: string;
  link?: LinkProps;
  imageSrc?: string;
  className?: string;
  darkBackground?: boolean;
  inlineImage?: boolean;
  childrenOnTop?: boolean;
  descriptionClassName?: string;
  animationDirection?: "LEFT" | "RIGHT";
  childrenClassName?: string;
  disableAnimation?: boolean;
}

const Article: FC<ArticleProps> = ({
  variant,
  inclinedTitle: inclinedTitleTKey,
  title,
  style,
  description,
  link,
  imageSrc,
  children,
  className,
  darkBackground,
  inlineImage,
  childrenOnTop,
  descriptionClassName,
  animationDirection = "LEFT",
  childrenClassName,
  disableAnimation,
}) => {
  const { t } = useTranslation();
  const sizeInfo = useWindowResize();
  const { ref, controls } = useScroll(0.3);
  const location = useLocation();

  const isMano = location.pathname.includes("/mano");
  const isTravertin = location.pathname.includes("/travertin");
  const inclinedTitle = inclinedTitleTKey && t(inclinedTitleTKey);

  const animations = {
    subtitle: {
      LEFT: subTitleAnimation,
      RIGHT: subTitleAnimationRight,
    },
    title: {
      LEFT: titleAnimationLeft,
      RIGHT: titleAnimationRight,
    },
  };

  const getTextPart = (className?: string) => {
    const commonClasses = [
      { "text-white": darkBackground },
      { "text-mano4": isMano && !darkBackground },
      { "text-travertin5": isTravertin && !darkBackground },
    ];

    return (
      <motion.div
        animate={controls}
        ref={ref}
        initial={"hidden"}
        className={classNames("flex flex-col", className, {
          "ml-20": inlineImage,
          "items-center": variant !== ArticleVariant.HEADER,
        })}
      >
        <motion.p
          className={classNames(inclinedTitleClass, ...commonClasses)}
          variants={
            disableAnimation
              ? undefined
              : animations.subtitle[animationDirection]
          }
        >
          {inclinedTitle}
        </motion.p>
        <motion.p
          className={classNames(titleClass, ...commonClasses)}
          variants={
            disableAnimation ? undefined : animations.title[animationDirection]
          }
        >
          {t(title)}
        </motion.p>
        {description && (
          <motion.p
            className={classNames(
              descriptionClassName,
              descriptionClass,
              ...commonClasses
            )}
            variants={disableAnimation ? undefined : delayedFadeInAnimation}
          >
            {t(description)}
          </motion.p>
        )}
        {link && variant !== ArticleVariant.HEADER && (
          <motion.div
            variants={disableAnimation ? undefined : delayedFadeInAnimation}
          >
            <Link
              to={link.href}
              className={classNames(
                "mb-5 w-fit relative flex",
                classes.Link,
                ...commonClasses
              )}
            >
              <motion.p
                className="mr-1"
                variants={disableAnimation ? undefined : delayedFadeInAnimation}
              >
                {t(link.label)}
              </motion.p>
              <ArrowRightAltIcon />
            </Link>
          </motion.div>
        )}
        {link && variant === ArticleVariant.HEADER && (
          <motion.div
            variants={disableAnimation ? undefined : delayedFadeInAnimation}
          >
            <Link to={link.href} className={"mb-5"}>
              <button className="w-fit text-mano4 font-bold border-color-white">
                {t(link.label)}
              </button>
            </Link>
          </motion.div>
        )}
      </motion.div>
    );
  };

  const inlineImagePart = (
    <ImageWrapper
      src={imageSrc}
      alt={inclinedTitle}
      className={classNames("object-cover", { "w-1/2": inlineImage })}
      disableAnimation={disableAnimation}
    />
  );

  if (variant === ArticleVariant.HEADER) {
    return (
      <motion.div
        className={classNames("flex flex-col w-full lg:flex-row", className)}
        style={style}
        ref={ref}
        animate={controls}
        initial={"hidden"}
      >
        <motion.div
          variants={disableAnimation ? undefined : fadeInAnimation}
          className={"flex w-full w-max-9xl"}
        >
          <div className="flex justify-center align-center py-20 lg:w-1/2 lg:py-0">
            {getTextPart("justify-center px-5 md:px-10 xl:px-40 w-3/4")}
          </div>
          {!sizeInfo.underLg && (
            <ImageWrapper
              src={imageSrc}
              alt={inclinedTitle}
              className={"object-cover w-1/2"}
              disableAnimation={disableAnimation}
            />
          )}
        </motion.div>
      </motion.div>
    );
  }

  if (variant === ArticleVariant.SEPARATED_ITEMS) {
    return (
      <div
        className={classNames("flex flex-col w-full", className)}
        style={style}
      >
        <div className="flex justify-center w-full align-center">
          {getTextPart("justify-center px-5 md:px-10 lg:px-24")}
        </div>
        <div className="flex justify-center mt-10">{children}</div>
      </div>
    );
  }

  if (
    !variant ||
    variant === ArticleVariant.NORMAL ||
    ArticleVariant.HIGHLIGHT
  ) {
    return (
      <div
        className={classNames(
          "px-5 md:px-10 mb-20 flex",
          className,
          inlineImage ? "flex-row text-left" : "flex-col text-center",
          { "max-w-7xl": variant !== ArticleVariant.HIGHLIGHT }
        )}
        style={style}
      >
        {childrenOnTop && (
          <motion.div
            ref={ref}
            initial={"hidden"}
            animate={controls}
            variants={disableAnimation ? undefined : delayedFadeInAnimation}
            className={classNames(childrenClassName, "h-fit")}
          >
            {children}
          </motion.div>
        )}
        {imageSrc && inlineImage && inlineImagePart}
        {getTextPart()}
        {imageSrc && !inlineImage && (
          <ImageWrapper
            src={imageSrc}
            alt={inclinedTitle}
            className={"mt-5 object-cover"}
            disableAnimation={disableAnimation}
          />
        )}
        {children && !childrenOnTop && children}
      </div>
    );
  }

  return null;
};

export default Article;
