import { FC } from "react";

import {
  Article,
  CardList,
  CarouselWrapper,
  DetailedList,
  PageWrapper,
  WelcomeSection,
  useWindowResize,
} from "components";
import {
  TRAVERTIN_DETAILED_LIST_ELEMENTS,
  TRAVERTIN_LIST_ITEMS,
} from "constants/listItems";
import {
  MANO_GRAPE_PHOTO,
  TRAVERTIN_HOMEPAGE_CAROUSAL_IMAGES,
} from "constants/imageLists";

import { Apartment, ArticleVariant } from "models";
import "./HomePage.overrides.scss";

const TravertinHomePage: FC = () => {
  const sizeInfo = useWindowResize();

  return (
    <PageWrapper
      apartment={Apartment.TRAVERTIN}
      useTransparentNavbar
      hasContactArticle
    >
      <WelcomeSection
        apartment={Apartment.TRAVERTIN}
        inclinedTitle={"TRAVERTIN.WELCOME_SECTION.INCLINED"}
        title={"TRAVERTIN.WELCOME_SECTION.TITLE"}
        description={"TRAVERTIN.WELCOME_SECTION.DESCRIPTION"}
      />
      <Article
        className={"IntroductionArticle"}
        inclinedTitle={"COMMON.OUR_APARTMENT"}
        title={"TRAVERTIN.INTRO_ARTICLE.TITLE"}
        description={"TRAVERTIN.INTRO_ARTICLE.DESCRIPTION"}
        link={{ label: "NAVIGATION.LINKS.GALLERY", href: "/travertin/gallery" }}
        children={
          <CarouselWrapper imageUrls={TRAVERTIN_HOMEPAGE_CAROUSAL_IMAGES} />
        }
      />
      <Article
        inclinedTitle={"TRAVERTIN.CARDLIST_ARTICLE.INCLINED"}
        title={"COMMON.SERVICES"}
        children={<CardList elements={TRAVERTIN_LIST_ITEMS} />}
        animationDirection={"RIGHT"}
      />
      <Article
        variant={ArticleVariant.HIGHLIGHT}
        inclinedTitle={"TRAVERTIN.PROMOTION_ARTICLE.INCLINED"}
        title={"TRAVERTIN.PROMOTION_ARTICLE.TITLE"}
        description={"TRAVERTIN.PROMOTION_ARTICLE.DESCRIPTION"}
        link={{ label: "COMMON.VIEW", href: "/travertin/prices" }}
        className={"bg-travertin4 py-20 w-full text-white"}
        darkBackground
      />
      <Article
        inclinedTitle={"COMMON.EGER.INCLINED"}
        title={"COMMON.EGER.TITLE"}
        description={"COMMON.EGER.DESCRIPTION"}
        descriptionClassName={"text-justify"}
        imageSrc={MANO_GRAPE_PHOTO}
        inlineImage={!sizeInfo.underXl}
        animationDirection={"RIGHT"}
      />
      <DetailedList elements={TRAVERTIN_DETAILED_LIST_ELEMENTS} />
    </PageWrapper>
  );
};

export default TravertinHomePage;
