import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { animateScroll } from "react-scroll";

export function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    animateScroll.scrollToTop({ duration: 400 });
  }, [pathname]);

  return null;
}
