import { FC, useEffect, useState } from "react";
import Select from "react-select";
import Creatable from "react-select/creatable";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { ErrorMessage, Formik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { Article, DetailedList } from "components";
import {
  Apartment,
  ArticleVariant,
  CalculatorValues,
  DetailedListColumn,
  PriceCalculatorData,
  PriceList,
} from "models";
import { MANO_PRICES, OTHER_PRICES, TRAVERTIN_PRICES } from "constants/prices";

interface PriceCalculatorProps {
  apartment: Apartment;
}

const PriceCalculator: FC<PriceCalculatorProps> = ({ apartment }) => {
  const { t } = useTranslation();

  const isMano = apartment === Apartment.MANO;
  const isTravertin = apartment === Apartment.TRAVERTIN;

  const [submitted, setSubmitted] = useState(false);
  const [formTouched, setFormTouched] = useState(false);
  const [prices, setPrices] = useState<PriceList | null>();
  const [formValues, setFormValues] = useState<CalculatorValues>({
    persons: 0,
    nights: 0,
    airConditioner: false,
  });
  const [listElements, setListElements] = useState<
    DetailedListColumn[] | null
  >();

  let data: PriceCalculatorData[] = [
    {
      label: "COMMON.PRICES.CALCULATOR.SEARCH.PERSON",
      placeholder: "COMMON.PRICES.CALCULATOR.SEARCH.PERSON",
      name: "persons",
      options: [
        { value: 2, label: `2 ${t("COMMON.PEOPLE")}` },
        { value: 3, label: `3 ${t("COMMON.PEOPLE")}` },
        { value: 4, label: `4 ${t("COMMON.PEOPLE")}` },
        { value: 5, label: `5 ${t("COMMON.PEOPLE")}` },
      ],
      creatable: false,
    },
    {
      label: "COMMON.PRICES.CALCULATOR.SEARCH.NIGHTS",
      placeholder: "COMMON.PRICES.CALCULATOR.SEARCH.NIGHTS",
      name: "nights",
      options: [
        { value: 1, label: `1 ${t("COMMON.NIGHT")}` },
        { value: 2, label: `2 ${t("COMMON.NIGHTS")}` },
        { value: 3, label: `3 ${t("COMMON.NIGHTS")}` },
        { value: 4, label: `4 ${t("COMMON.NIGHTS")}` },
        { value: 5, label: `5 ${t("COMMON.NIGHTS")}` },
        { value: 6, label: `6 ${t("COMMON.NIGHTS")}` },
        { value: 7, label: `7 ${t("COMMON.NIGHTS")}` },
      ],
      creatable: true,
    },
  ];

  if (isTravertin) {
    data[0].options.push({ value: 6, label: `6 ${t("COMMON.PEOPLE")}` });
  }

  useEffect(() => {
    const getPrices = () => {
      const { persons, nights, airConditioner: air } = formValues;
      const accomodation = persons * nights * getPricePerPerson(persons);
      const taxes = persons * nights * OTHER_PRICES.TAX;
      const airConditioner = air ? nights * OTHER_PRICES.AIRCONDITIONER : 0;
      setPrices({
        accomodation,
        taxes,
        airConditioner,
        sum: accomodation + taxes + airConditioner,
      });
    };
    getPrices();
  }, [formValues]);

  useEffect(() => {
    if (!prices) return;
    const { accomodation, taxes, airConditioner } = prices;
    let items = [
      {
        label: "COMMON.ACCOMODATION",
        value: accomodation,
        unit: "COMMON.HUF",
      },
      {
        label: "COMMON.TAX",
        value: taxes,
        unit: "COMMON.HUF",
      },
      {
        label: "COMMON.PRICES.CALCULATOR.AIR_CONDITIONER",
        value: airConditioner,
        unit: "COMMON.HUF",
      },
      {
        label: "COMMON.SUM",
        value: accomodation + taxes + airConditioner,
        unit: "COMMON.HUF",
      },
    ];

    if (isTravertin) {
      items.splice(2, 1);
    }

    const elements: DetailedListColumn[] = [
      {
        title: "COMMON.PRICES.CALCULATOR.LIST_TITLE",
        items,
      },
    ];

    setListElements(elements);
  }, [prices]);

  const getPricePerPerson = (persons: number) => {
    const pricesObject = {
      MANO: MANO_PRICES,
      TRAVERTIN: TRAVERTIN_PRICES,
    };

    return (
      Object.entries(pricesObject[apartment]).find(
        (entry) => entry[0] === `${persons}PERSON`
      )?.[1] || 0
    );
  };

  const onSubmit = (values: CalculatorValues) => {
    setSubmitted(true);
    setFormValues(values);
    if (!formTouched) setFormTouched(true);
  };

  return (
    <div
      className={classNames(
        "flex flex-col w-full items-center bg-mano2",
        { "bg-mano2": isMano },
        { "bg-travertin1": isTravertin }
      )}
    >
      <Article
        variant={ArticleVariant.HIGHLIGHT}
        title="COMMON.PRICES.CALCULATOR.TITLE"
        description="COMMON.PRICES.CALCULATOR.DESCRIPTION"
        className="mb-4 w-full px-5 md:mx-10 pt-20 flex flex-col items-center"
      >
        <Formik
          initialValues={formValues}
          validationSchema={Yup.object().shape({
            persons: Yup.number().min(2, t("VALIDATION.REQUIRED")),
            nights: Yup.number().min(1, t("VALIDATION.REQUIRED")),
            airConditioner: Yup.boolean(),
          })}
          validateOnChange={true}
          validateOnBlur={false}
          onSubmit={(values) => onSubmit(values)}
        >
          {({ values, handleSubmit, setFieldValue }) => (
            <form
              className="flex flex-col w-full max-w-7xl items-center"
              onSubmit={handleSubmit}
              autoComplete="off"
              autoCorrect="off"
            >
              <div className="flex w-full flex-col items-center mt-10 ">
                <div className="flex flex-row w-full justify-center max-w-7xl">
                  {data.map((item) => (
                    <div className="flex-col items-start text-left w-1/2 px-5 mb-5">
                      <label htmlFor={item.name} className="pl-1">
                        {t(item.label)}
                      </label>
                      {item.creatable && (
                        <>
                          <Creatable
                            formatCreateLabel={(inputValue) => (
                              <p>{`${inputValue} ${t("COMMON.NIGHTS")}`}</p>
                            )}
                            placeholder={t(item.placeholder)}
                            name={item.name}
                            onChange={(value) => {
                              setFieldValue(item.name, value?.value || 0);
                              submitted &&
                                onSubmit({
                                  ...values,
                                  [item.name]: value?.value || 0,
                                });
                            }}
                            isClearable
                            isSearchable
                            options={item.options}
                          />
                          <ErrorMessage
                            name={item.name}
                            component={"div"}
                            className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 px-4 py-2"
                          />
                        </>
                      )}
                      {!item.creatable && (
                        <>
                          <Select
                            placeholder={t(item.placeholder)}
                            name={item.name}
                            onChange={(value) => {
                              setFieldValue(item.name, value?.value || 0);
                              submitted &&
                                onSubmit({
                                  ...values,
                                  [item.name]: value?.value || 0,
                                });
                            }}
                            isClearable
                            isSearchable={false}
                            options={item.options}
                          />
                          <ErrorMessage
                            name={item.name}
                            component={"div"}
                            className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 px-4 py-2"
                          />
                        </>
                      )}
                    </div>
                  ))}
                </div>

                {!isTravertin && (
                  <div className="flex flex-row w-full text-start mt-5 px-5 justify-center">
                    <FormControlLabel
                      name="airConditioner"
                      label={t(
                        "COMMON.PRICES.CALCULATOR.AIR_CONDITIONER"
                      ).toString()}
                      labelPlacement="start"
                      control={
                        <Switch
                          onChange={(event) => {
                            setFieldValue(
                              "airConditioner",
                              event.target.checked
                            );
                            submitted &&
                              onSubmit({
                                ...values,
                                airConditioner: event.target.checked,
                              });
                          }}
                        />
                      }
                    />
                  </div>
                )}
              </div>
              {!submitted && (
                <button
                  type="submit"
                  className={classNames(
                    "self-center mt-10 w-fit font-semibold border-2 border-mano4 border-solid",
                    { "mb-24": !formTouched }
                  )}
                >
                  {t("COMMON.ESTIMATE")}
                </button>
              )}
            </form>
          )}
        </Formik>
      </Article>
      {formTouched && listElements && (
        <DetailedList elements={listElements} singleColumn hasSummary />
      )}
    </div>
  );
};

export default PriceCalculator;
