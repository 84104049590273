import { transform } from "lodash";

const animationDurations = {
  short: 0.2,
  medium: 0.4,
  long: 0.6,
};

export const titleAnimationLeft = {
  hidden: { x: -500, opacity: 0 },
  show: {
    x: 0,
    opacity: 1,
    transition: {
      delay: 0.4,
      duration: animationDurations.long,
      ease: "easeOut",
    },
  },
};

export const titleAnimationRight = {
  hidden: { x: 500, opacity: 0 },
  show: {
    x: 0,
    opacity: 1,
    transition: {
      delay: 0.4,
      duration: animationDurations.long,
      ease: "easeOut",
    },
  },
};

export const subTitleAnimation = {
  hidden: { x: -500, opacity: 0 },
  show: {
    x: 0,
    opacity: 1,
    transition: {
      delay: 0.6,
      duration: animationDurations.long,
      ease: "easeOut",
    },
  },
};

export const subTitleAnimationRight = {
  hidden: { x: 500, opacity: 0 },
  show: {
    x: 0,
    opacity: 1,
    transition: {
      delay: 0.6,
      duration: animationDurations.long,
      ease: "easeOut",
    },
  },
};

export const shortFadeInAnimation = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      delay: 0.2,
      duration: animationDurations.medium,
      ease: "easeOut",
    },
  },
};

export const fadeInAnimation = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      delay: 0.3,
      duration: animationDurations.long,
      ease: "easeOut",
    },
  },
};

export const delayedFadeInAnimation = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      delay: 1,
      duration: animationDurations.long,
      ease: "easeOut",
    },
  },
};

export const fadeInListNoDelayAnimation = {
  willChange: transform,
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      delay: 0,
      duration: animationDurations.long,
      ease: "easeOut",
      staggerChildren: 0.2,
    },
  },
};

export const fadeInListAnimation = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      delay: 1,
      duration: animationDurations.long,
      ease: "easeOut",
      staggerChildren: 0.2,
    },
  },
};

export const fadeInListItemAnimation = {
  willChange: transform,
  hidden: { opacity: 0, y: -120 },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      duration: animationDurations.medium,
      ease: "easeOut",
    },
  },
};

export const fadeInListItemShortAnimation = {
  willChange: transform,
  hidden: { opacity: 0, y: -120 },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      duration: animationDurations.short,
      ease: "easeOut",
    },
  },
};

export const swimInUpAnimation = {
  willChange: transform,
  hidden: { opacity: 0, y: 500 },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.75,
      ease: "easeOut",
    },
  },
};

export const swimInDownAnimation = {
  willChange: transform,
  hidden: { opacity: 0, y: -500 },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      duration: animationDurations.medium,
      ease: "easeOut",
    },
  },
};
