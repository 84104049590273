import { Navigate, PathRouteProps } from "react-router-dom";

import {
  ApartmentSelector,
  ManoHomePage,
  ManoPrices,
  TravertinHomePage,
  TravertinPrices,
} from "pages";
import { ContactPage, GalleryPage } from "components";
import { Apartment } from "models";
import {
  MANO_GALLERY_IMAGES,
  TRAVERTIN_GALLERY_IMAGES,
} from "constants/imageLists";

export const routes: PathRouteProps[] = [
  {
    path: "/",
    element: <ApartmentSelector />,
  },
  {
    path: "/mano",
    element: <ManoHomePage />,
  },
  {
    path: "/mano/gallery",
    element: (
      <GalleryPage apartment={Apartment.MANO} imageList={MANO_GALLERY_IMAGES} />
    ),
  },
  {
    path: "/mano/prices",
    element: <ManoPrices />,
  },
  {
    path: "/mano/contact",
    element: <ContactPage apartment={Apartment.MANO} />,
  },
  {
    path: "/travertin",
    element: <TravertinHomePage />,
  },
  {
    path: "/travertin/gallery",
    element: (
      <GalleryPage
        apartment={Apartment.TRAVERTIN}
        imageList={TRAVERTIN_GALLERY_IMAGES}
      />
    ),
  },
  { path: "/travertin/prices", element: <TravertinPrices /> },
  {
    path: "/travertin/contact",
    element: <ContactPage apartment={Apartment.TRAVERTIN} />,
  },
  {
    path: "*",
    element: <Navigate to="/" />,
  },
];
