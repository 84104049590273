import { FC, Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { Formik } from "formik";
import * as Yup from "yup";
import { TextFieldProps } from "@mui/material";
import { makeStyles } from "@mui/styles";
import TextField from "@mui/material/TextField";
import { send } from "emailjs-com";
import classNames from "classnames";
import { motion } from "framer-motion";

import { AppLoader, useScroll } from "components";
import { colors } from "constants/colors";
import { Apartment } from "models";

import classes from "./ContactForm.module.scss";
import "./ContactForm.overrides.scss";
import { delayedFadeInAnimation, fadeInAnimation } from "constants/animate";

interface ContactFormProps {
  setSent: Dispatch<SetStateAction<boolean>>;
  setEmailError: Dispatch<SetStateAction<boolean>>;
  isLoading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  apartment: Apartment;
}

const ContactForm: FC<ContactFormProps> = ({
  setSent,
  setEmailError,
  isLoading,
  setLoading,
  apartment,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { ref, controls } = useScroll(0.3);

  const isMano = location.pathname.includes("/mano");
  const isTravertin = location.pathname.includes("/travertin");

  const pageColors = {
    textColors: {
      MANO: "white",
      TRAVERTIN: colors.travertin5,
    },
    backgroundColors: {
      MANO: colors.mano4,
      TRAVERTIN: "white",
    },
  };

  const useStyles = makeStyles(() => ({
    root: {
      "& input:-webkit-autofill": {
        "-webkit-text-fill-color": `${pageColors.textColors[apartment]} !important`,
      },
      "& input:-webkit-autofill:focus": {
        "-webkit-text-fill-color": `${pageColors.textColors[apartment]} !important`,
      },
      "& .MuiInputLabel-root": {
        color: `${pageColors.textColors[apartment]} !important`,
      },
      "& .MuiFilledInput-input": {
        color: `${pageColors.textColors[apartment]} !important`,
        backgroundColor: `${pageColors.backgroundColors[apartment]} !important`,
      },
      "& .MuiFilledInput-root": {
        color: `${pageColors.textColors[apartment]} !important`,
        backgroundColor: `${pageColors.backgroundColors[apartment]} !important`,
      },
      "& input:-webkit-autofill, input:-webkit-autofill:focus": {
        boxShadow: `inset 0 0 0 1px ${pageColors.backgroundColors[apartment]}, inset 0 0 0 100px ${pageColors.backgroundColors[apartment]}`,
      },
    },
  }));

  const formClasses = useStyles();

  const StyledTextField = styled(TextField)`
    margin-bottom: 20px !important;
    textarea {
      color: white !important;
    }
  `;

  const backgrounds = {
    MANO: "bg-mano4",
    TRAVERTIN: "bg-white",
  };

  const StyledTextFieldCommonProps: TextFieldProps = {
    autoComplete: "new-password",
    variant: "filled",
    className: classNames(
      backgrounds[apartment],
      pageColors.textColors[apartment]
    ),
    fullWidth: true,
    color: "success",
    sx: { input: { color: "white" } },
  };

  const onSubmit = (values: any, errors: any) => {
    setLoading(true);

    const getApartment = () => {
      if (isMano) return "Manó apartman";
      if (isTravertin) return "Travertin";
      return "";
    };

    const templateParams = {
      apartment: getApartment(),
      from_name: values.name,
      from_email: values.email,
      subject: values.subject,
      message: values.message,
    };

    send(
      process.env.REACT_APP_EMAILJS_SERVICE_ID || "",
      process.env.REACT_APP_EMAILJS_TEMPLATE_ID || "",
      templateParams,
      process.env.REACT_APP_EMAILJS_USER_ID || ""
    )
      .then(() => {
        setSent(true);
      })
      .catch(() => {
        setEmailError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      {isLoading ? (
        <AppLoader />
      ) : (
        <Formik
          initialValues={{ name: "", email: "", subject: "", message: "" }}
          onSubmit={(values, errors) => onSubmit(values, errors)}
          validationSchema={Yup.object().shape({
            name: Yup.string()
              .required(t("VALIDATION.REQUIRED"))
              .min(
                5,
                t("VALIDATION.MINIMUM_LENGTH", { minCharsRequired: "5" })
              ),
            email: Yup.string()
              .email(t("VALIDATION.EMAIL"))
              .required(t("VALIDATION.REQUIRED")),
            subject: Yup.string()
              .required(t("VALIDATION.REQUIRED"))
              .min(
                5,
                t("VALIDATION.MINIMUM_LENGTH", { minCharsRequired: "5" })
              ),
            message: Yup.string()
              .required(t("VALIDATION.REQUIRED"))
              .min(
                5,
                t("VALIDATION.MINIMUM_LENGTH", { minCharsRequired: "5" })
              ),
          })}
          validateOnChange={true}
          validateOnBlur={false}
        >
          {({
            errors,
            touched,
            handleSubmit,
            handleChange,
            handleBlur,
            isSubmitting,
          }) => (
            <motion.form
              onSubmit={handleSubmit}
              className={classNames(
                "flex flex-col items-center w-full max-w-xs sm:max-w-xl my-14",
                formClasses.root
              )}
              autoComplete="off"
              autoCorrect="off"
              ref={ref}
              initial={"hidden"}
              animate={controls}
              variants={delayedFadeInAnimation}
            >
              <StyledTextField
                {...StyledTextFieldCommonProps}
                name="name"
                onChange={handleChange}
                onBlur={handleBlur}
                label={t("COMMON.CONTACTS.FORM.NAME")}
                InputLabelProps={{
                  style: {
                    color: errors.name && touched.name ? "red" : "white",
                  },
                }}
                error={!!errors.name && !!touched.name}
                helperText={!!errors.name && !!touched.name && errors.name}
              />
              <StyledTextField
                {...StyledTextFieldCommonProps}
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
                label={t("COMMON.CONTACTS.FORM.EMAIL")}
                InputLabelProps={{
                  style: {
                    color: errors.email && touched.email ? "red" : "white",
                  },
                }}
                error={!!errors.email && !!touched.email}
                helperText={!!errors.email && !!touched.email && errors.email}
              />
              <StyledTextField
                {...StyledTextFieldCommonProps}
                name="subject"
                onChange={handleChange}
                onBlur={handleBlur}
                label={t("COMMON.CONTACTS.FORM.SUBJECT")}
                InputLabelProps={{
                  style: {
                    color: errors.subject && touched.subject ? "red" : "white",
                  },
                }}
                error={!!errors.subject && !!touched.subject}
                helperText={
                  !!errors.subject && !!touched.subject && errors.subject
                }
              />
              <StyledTextField
                {...StyledTextFieldCommonProps}
                name="message"
                onChange={handleChange}
                onBlur={handleBlur}
                label={t("COMMON.CONTACTS.FORM.MESSAGE")}
                InputLabelProps={{
                  style: {
                    color: errors.message && touched.message ? "red" : "white",
                  },
                }}
                multiline
                minRows={10}
                error={!!errors.message && !!touched.message}
                helperText={
                  !!errors.message && !!touched.message && errors.message
                }
              />
              <button
                type="submit"
                disabled={isSubmitting}
                className={classNames(
                  "font-bold w-fit border-2 mt-5 self-end text-white border-white",
                  classes.SendButton
                )}
              >
                {t("COMMON.CONTACTS.FORM.SEND")}
              </button>
            </motion.form>
          )}
        </Formik>
      )}
    </>
  );
};

export default ContactForm;
