import { ImageListItemData } from "models";

export const manoOutsideUrl =
  "https://res.cloudinary.com/disyx1lwa/image/upload/{{transformation}}/v1646819987/egriapartman/mano1_el4wny.jpg";
const manoRoom1Url = `https://res.cloudinary.com/disyx1lwa/image/upload/{{transformation}}/v1646316159/egriapartman/1_wyxces.jpg`;
const manoRoom2Url = `https://res.cloudinary.com/disyx1lwa/image/upload/{{transformation}}/v1646316160/egriapartman/2_g3ybcq.jpg`;
const manoRoom3Url = `https://res.cloudinary.com/disyx1lwa/image/upload/{{transformation}}/v1646316160/egriapartman/3_pysyoj.jpg`;
const manoBathroomUrl = `https://res.cloudinary.com/disyx1lwa/image/upload/{{transformation}}/v1646316159/egriapartman/4_uhmvao.jpg`;
const manoStairsUrl = `https://res.cloudinary.com/disyx1lwa/image/upload/{{transformation}}/v1646316160/egriapartman/5_fpntxs.jpg`;
const manoKitchenUrl = `https://res.cloudinary.com/disyx1lwa/image/upload/{{transformation}}/v1646316159/egriapartman/6_lswcp0.jpg`;
const manoLivingRoom1Url = `https://res.cloudinary.com/disyx1lwa/image/upload/{{transformation}}/v1646316163/egriapartman/7_o9n03f.jpg`;
const manoLivingRoom2Url = `https://res.cloudinary.com/disyx1lwa/image/upload/{{transformation}}/v1646316163/egriapartman/8_fdx78w.jpg`;

export const travertinOutsideUrl =
  "https://res.cloudinary.com/disyx1lwa/image/upload/{{transformation}}/v1646421656/egriapartman/1_xlqdd8.jpg";
const travertinOutside2Url =
  "https://res.cloudinary.com/disyx1lwa/image/upload/{{transformation}}/v1651677531/egriapartman/travertin_uj/DSCN9117_ipl88m.jpg";
const travertinStatueUrl =
  "https://res.cloudinary.com/disyx1lwa/image/upload/{{transformation}}/v1651677539/egriapartman/travertin_uj/DSCN9113_1_wtkxox.jpg";
const travertintLivingRoom1Url =
  "https://res.cloudinary.com/disyx1lwa/image/upload/{{transformation}}/v1651677531/egriapartman/travertin_uj/DSCN9005_1_ruccvb.jpg";
const travertintLivingRoom2Url =
  "https://res.cloudinary.com/disyx1lwa/image/upload/{{transformation}}/v1646421654/egriapartman/9_fw3p49.jpg";
const travertintLivingRoom3Url =
  "https://res.cloudinary.com/disyx1lwa/image/upload/{{transformation}}/v1651677538/egriapartman/travertin_uj/DSCN9013_tjndg0.jpg";
const travertintLivingRoom4Url =
  "https://res.cloudinary.com/disyx1lwa/image/upload/{{transformation}}/v1651677531/egriapartman/travertin_uj/DSCN9764_1_kjeiew.jpg";
const travertintLivingRoom5Url =
  "https://res.cloudinary.com/disyx1lwa/image/upload/{{transformation}}/v1651677531/egriapartman/travertin_uj/DSCN9770_1_kms9sj.jpg";
const travertinPoppyRoom1Url =
  "https://res.cloudinary.com/disyx1lwa/image/upload/{{transformation}}/v1646421654/egriapartman/12_ht1nwf.jpg";
const travertinPoppyRoom2Url =
  "https://res.cloudinary.com/disyx1lwa/image/upload/{{transformation}}/v1651677534/egriapartman/travertin_uj/DSCN9020_1_qsaz05.jpg";
const travertinPoppyRoom3Url =
  "https://res.cloudinary.com/disyx1lwa/image/upload/{{transformation}}/v1651940325/egriapartman/travertin_uj/DSCN9083_1_mwvem5.jpg";
const travertinBathroom1Url =
  "https://res.cloudinary.com/disyx1lwa/image/upload/{{transformation}}/v1651677534/egriapartman/travertin_uj/DSCN9042_dgx0tw.jpg";
const travertinBathroom2Url =
  "https://res.cloudinary.com/disyx1lwa/image/upload/{{transformation}}/v1646421654/egriapartman/11_u6gil8.jpg";
const travertinBathroom3Url =
  "https://res.cloudinary.com/disyx1lwa/image/upload/{{transformation}}/v1646421654/egriapartman/6_z4a5oj.jpg";
const travertinBathroom4Url =
  "https://res.cloudinary.com/disyx1lwa/image/upload/{{transformation}}/v1651677535/egriapartman/travertin_uj/DSCN9037_g0yadb.jpg";

const travertinRoseRoom1Url =
  "https://res.cloudinary.com/disyx1lwa/image/upload/{{transformation}}/v1651677538/egriapartman/travertin_uj/DSCN9059_1_ihlvqf.jpg";
const travertinRoseRoom2Url =
  "https://res.cloudinary.com/disyx1lwa/image/upload/{{transformation}}/v1651677537/egriapartman/travertin_uj/DSCN9076_otakpg.jpg";
const travertinRoseRoom3Url =
  "https://res.cloudinary.com/disyx1lwa/image/upload/{{transformation}}/v1651677532/egriapartman/travertin_uj/DSCN9110_1_zkotjg.jpg";
const travertinRoseRoom4Url =
  "https://res.cloudinary.com/disyx1lwa/image/upload/{{transformation}}/v1651677535/egriapartman/travertin_uj/DSCN9047_w8krcj.jpg";

const travertinRelax1Url =
  "https://res.cloudinary.com/disyx1lwa/image/upload/{{transformation}}/v1651677535/egriapartman/travertin_uj/DSCN9095_zgi8ew.jpg";
const travertinRelax2Url =
  "https://res.cloudinary.com/disyx1lwa/image/upload/{{transformation}}/v1651677531/egriapartman/travertin_uj/DSCN9103_1_gctaao.jpg";

const travertinSauna2Url =
  "https://res.cloudinary.com/disyx1lwa/image/upload/{{transformation}}/v1651677534/egriapartman/travertin_uj/DSCN9106_1_ofaglu.jpg";
const travertinSauna3Url =
  "https://res.cloudinary.com/disyx1lwa/image/upload/{{transformation}}/v1651677534/egriapartman/travertin_uj/DSCN9097_1_swfqhb.jpg";

export const MANO_HOMEPAGE_CAROUSAL_IMAGES = [
  manoRoom1Url,
  manoRoom2Url,
  manoRoom3Url,
  manoBathroomUrl,
  manoStairsUrl,
  manoKitchenUrl,
  manoLivingRoom1Url,
  manoLivingRoom2Url,
  manoOutsideUrl,
];

export const TRAVERTIN_HOMEPAGE_CAROUSAL_IMAGES = [
  travertinStatueUrl,
  travertintLivingRoom1Url,
  travertintLivingRoom2Url,
  travertintLivingRoom3Url,
  travertintLivingRoom4Url,
  travertintLivingRoom5Url,
  travertinPoppyRoom1Url,
  travertinPoppyRoom2Url,
  travertinPoppyRoom3Url,
];

export const MANO_GALLERY_IMAGES: ImageListItemData[] = [
  {
    src: manoOutsideUrl,
    title: "Outside",
  },
  {
    src: manoRoom1Url,
    title: "Room1",
  },
  {
    src: manoRoom2Url,
    title: "Room2",
  },
  {
    src: manoRoom3Url,
    title: "Room3",
  },
  {
    src: manoBathroomUrl,
    title: "Bathroom",
  },
  {
    src: manoStairsUrl,
    title: "Stairs",
  },
  {
    src: manoKitchenUrl,
    title: "Kitchen",
  },
  {
    src: manoLivingRoom1Url,
    title: "Living room",
  },
  {
    src: manoLivingRoom2Url,
    title: "Living room 2",
  },
];

export const TRAVERTIN_GALLERY_IMAGES: ImageListItemData[] = [
  {
    src: travertinStatueUrl,
    title: "Statue",
  },
  {
    src: travertintLivingRoom1Url,
    title: "LivingRoom1",
  },
  {
    src: travertintLivingRoom2Url,
    title: "LivingRoom2",
  },
  {
    src: travertintLivingRoom3Url,
    title: "LivingRoom3",
  },
  {
    src: travertintLivingRoom4Url,
    title: "LivingRoom4",
  },
  {
    src: travertintLivingRoom5Url,
    title: "LivingRoom5",
  },
  {
    src: travertinPoppyRoom1Url,
    title: "PoppyRoom1",
  },
  {
    src: travertinPoppyRoom2Url,
    title: "PoppyRoom2",
  },
  {
    src: travertinPoppyRoom3Url,
    title: "PoppyRoom3",
  },
  {
    src: travertinBathroom1Url,
    title: "Bathroom1",
  },
  {
    src: travertinBathroom2Url,
    title: "Bathroom2",
  },
  {
    src: travertinBathroom3Url,
    title: "Bathroom3",
  },
  {
    src: travertinBathroom4Url,
    title: "Bathroom4",
  },
  {
    src: travertinRoseRoom1Url,
    title: "RoseRoom1",
  },
  {
    src: travertinRoseRoom2Url,
    title: "RoseRoom2",
  },
  {
    src: travertinRoseRoom3Url,
    title: "RoseRoom3",
  },
  {
    src: travertinRoseRoom4Url,
    title: "RoseRoom4",
  },
  {
    src: travertinRelax1Url,
    title: "Relax1",
  },
  {
    src: travertinRelax2Url,
    title: "Relax2",
  },
  {
    src: travertinSauna2Url,
    title: "Sauna2",
  },
  {
    src: travertinSauna3Url,
    title: "Sauna3",
  },
  {
    src: travertinOutside2Url,
    title: "Outside2",
  },
];

export const MANO_GRAPE_PHOTO =
  "https://res.cloudinary.com/disyx1lwa/image/upload/v1647612241/egriapartman/grape_sth8ic_b6ljri.webp";
