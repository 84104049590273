export const MANO_PRICES = {
  "2PERSON": 12000,
  "3PERSON": 8700,
  "4PERSON": 6900,
  "5PERSON": 6000,
};

export const TRAVERTIN_PRICES = {
  "2PERSON": 25500,
  "3PERSON": 20000,
  "4PERSON": 15000,
  "5PERSON": 14500,
  "6PERSON": 14000,
};

export const OTHER_PRICES = {
  TAX: 500,
  AIRCONDITIONER: 1500,
};
