import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import DirectionsWalkIcon from "@mui/icons-material/DirectionsWalk";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import classNames from "classnames";
import i18n from "i18n";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";

import { useScroll } from "components";
import {
  fadeInAnimation,
  fadeInListItemAnimation,
  swimInUpAnimation,
} from "constants/animate";
import { colors } from "constants/colors";
import { DetailedListColumn, TravelMethod } from "models";

export interface DetailedListProps {
  elements: DetailedListColumn[];
  hasToggleButtons?: boolean;
  singleColumn?: boolean;
  hasSummary?: boolean;
}

const DetailedList: FC<DetailedListProps> = ({
  elements,
  hasToggleButtons,
  singleColumn,
  hasSummary,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { ref, controls } = useScroll(0.3);

  const [method, setMethod] = useState<TravelMethod>(TravelMethod.walk);

  const isMano = location.pathname.includes("/mano");
  const isTravertin = location.pathname.includes("/travertin");

  const getHtmlColor = () => {
    if (isMano) return colors.mano5;
    if (isTravertin) return colors.travertin5;
    return "0000000";
  };

  const handleAlignment = (
    _: React.MouseEvent<HTMLElement>,
    method: TravelMethod
  ) => {
    setMethod(TravelMethod[method]);
  };

  const getValueWith = (value: string | number, unit: string) =>
    `${value} ${t(unit)}`;

  return (
    <motion.div
      className="flex flex-col w-full px-5 md:px-10 max-w-7xl items-center relative"
      ref={ref}
      initial={"hidden"}
      animate={controls}
    >
      {hasToggleButtons && (
        <ToggleButtonGroup
          color="standard"
          onChange={handleAlignment}
          value={method}
          exclusive
          className="mb-12"
        >
          <ToggleButton value={TravelMethod.walk}>
            <DirectionsWalkIcon htmlColor={getHtmlColor()} />
          </ToggleButton>
          <ToggleButton value={TravelMethod.car}>
            <DirectionsCarIcon htmlColor={getHtmlColor()} />
          </ToggleButton>
        </ToggleButtonGroup>
      )}
      <div
        className={classNames(
          "flex flex-col items-start md:flex-row w-full md:justify-between pb-24",
          { "mt-20": !hasToggleButtons }
        )}
      >
        {elements.map((element) => (
          <motion.div
            className={classNames(
              "flex flex-col w-full px-5 md:px-10 mb-20 md:mb-0",
              { "md:w-1/2": !singleColumn },
              { "text-mano5": isMano },
              { "text-travertin5": isTravertin }
            )}
            key={element.title}
            variants={fadeInListItemAnimation}
          >
            <p className={"text-4xl mb-2.5"}>{t(element.title)}</p>
            {element.items.map((item, index) => (
              <div
                className="flex flex-wrap justify-between py-5 border-b border-dotted border-black"
                key={item.label}
              >
                <p
                  className={classNames({
                    "font-bold":
                      index === element.items.length - 1 && hasSummary,
                  })}
                >
                  {t(item.label)}
                </p>
                {item.value2 ? (
                  <p
                    className={classNames({
                      "font-bold":
                        index === element.items.length - 1 && hasSummary,
                    })}
                  >
                    {method === TravelMethod.walk
                      ? getValueWith(item.value, item.unit)
                      : getValueWith(item.value2, item.unit) || item.value}
                  </p>
                ) : (
                  <p
                    className={classNames({
                      "font-bold":
                        index === element.items.length - 1 && hasSummary,
                    })}
                  >
                    {getValueWith(
                      item.value.toLocaleString(
                        i18n.language === "hu" ? "hu-HU" : "en-EN"
                      ),
                      item.unit
                    )}
                  </p>
                )}
              </div>
            ))}
          </motion.div>
        ))}
      </div>
    </motion.div>
  );
};

export default DetailedList;
