import { FC } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { animateScroll as scroll } from "react-scroll";
import { IoIosArrowDropup } from "react-icons/io";
import { Tooltip } from "@mui/material";
import ReactCountryFlag from "react-country-flag";
import i18n from "i18n";
import { motion } from "framer-motion";

import { NavigationBarProps, ImageWrapper, useScroll } from "components";
import { subTitleAnimationRight, swimInUpAnimation } from "constants/animate";
import { szepCardImageUrl } from "constants/links";
import { Apartment } from "models";

import { FooterColumn } from "./FooterColumn/FooterColumn";

import classes from "./Footer.module.scss";

interface FooterProps extends Omit<NavigationBarProps, "buttonLabel"> {
  email: string;
  telephone: string;
  address: string;
  apartment: Apartment | undefined;
  compactView?: boolean;
}

const Footer: FC<FooterProps> = ({
  logoText,
  links,
  email,
  telephone,
  address,
  compactView,
  apartment,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { ref, controls } = useScroll(compactView ? 0 : 0.35);

  const isMano = location.pathname.includes("/mano");
  const isTravertin = location.pathname.includes("/travertin");

  return compactView ? (
    <motion.div
      className={classNames(
        "flex justify-center items-center text-center py-5 text-white bg-mano5"
      )}
      ref={ref}
      initial={"hidden"}
      animate={controls}
    >
      <motion.p variants={swimInUpAnimation}>{t("FOOTER.RIGHTS")}</motion.p>
    </motion.div>
  ) : (
    <motion.div
      className={classNames(
        "text-white w-full flex flex-col items-center justify-center text center relative",
        { "bg-mano4": isMano },
        { "bg-travertin4": isTravertin }
      )}
      ref={ref}
      initial={"hidden"}
      animate={controls}
    >
      <IoIosArrowDropup
        className={classNames(
          "absolute top-0 left-1/2 rounded-full text-5xl md:text-6xl m-0 cursor-pointer",
          classes.FooterCircle,
          { "bg-mano4 hover:text-mano3": isMano },
          { "bg-travertin4 hover:text-travertin3": isTravertin }
        )}
        onClick={() => scroll.scrollToTop({ duration: 400 })}
      />
      <p className="text-lg md:text-xl py-10 text-center font-bold">
        {t(logoText)}
      </p>
      <motion.div
        className="flex flex-col md:flex-row w-full justify-between items-start max-w-7xl pb-10 px-5 md:px-10"
        variants={swimInUpAnimation}
      >
        <FooterColumn large>
          <p className="my-2">{t(`FOOTER.${apartment}_DESCRIPTION`)}</p>
        </FooterColumn>
        <FooterColumn>
          <Link to={"/mano"} className={"my-2"}>
            {t("MANO.NAME")}
          </Link>
          <Link to={"/travertin"} className={"my-2"}>
            {t("TRAVERTIN.NAME")}
          </Link>
          <Link to={"/"} className={"my-2"}>
            {"Egriapartman.hu"}
          </Link>
        </FooterColumn>
        <FooterColumn>
          {links.map((link) => (
            <Link to={link.route} className={"my-2"}>
              {t(link.label)}
            </Link>
          ))}
        </FooterColumn>
        <FooterColumn>
          <p className={"my-2"}>{email}</p>
          <p className={"my-2"}>{telephone}</p>
          <p className={"my-2"}>{address}</p>
        </FooterColumn>
      </motion.div>
      <motion.div
        className="w-full max-w-7xl px-5 md:px-10"
        variants={subTitleAnimationRight}
      >
        <div className="flex flex-col md:flex-row justify-center items-center py-5 border-white border-solid border-t">
          <div className="flex items-center md:w-1/3 md:justify-start mb-10 md:mb-0">
            <Tooltip title={t("NAVIGATION.LANGUAGE_TOOLTIP").toString()}>
              <ReactCountryFlag
                countryCode={i18n.language === "hu" ? "GB" : "HU"}
                className="m-0 py-1 text-3xl leading-6 cursor-pointer rounded-lg"
                svg
                onClick={() => {
                  i18n.changeLanguage(i18n.language === "hu" ? "en" : "hu");
                }}
              />
            </Tooltip>
          </div>
          <p className="md:w-1/3 text-center mb-10 md:mb-0">
            {t("FOOTER.RIGHTS")}
          </p>
          <div className="flex items-center md:w-1/3 justify-end ">
            <ImageWrapper
              src={szepCardImageUrl}
              alt="cards"
              className={"max-h-12 md:max-h-14"}
            />
            <p className="ml-5">{t("FOOTER.SZEP_CARD")}</p>
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default Footer;
