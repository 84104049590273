import { FC, useEffect, useState } from "react";
import classNames from "classnames";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { motion } from "framer-motion";

import { useWindowResize, ImageWrapper, useScroll } from "components";
import { fadeInAnimation, delayedFadeInAnimation } from "constants/animate";
import { colors } from "constants/colors";

import "./CarouselWrapper.overrides.scss";

interface CarouselWrapperProps {
  imageUrls?: string[];
  elements?: JSX.Element[];
}

export const CarouselWrapper: FC<CarouselWrapperProps> = ({
  imageUrls,
  elements,
}) => {
  if (!imageUrls && !elements) throw Error("Images or elements must be set!");
  const sizeInfo = useWindowResize();
  const { ref, controls } = useScroll(0.1);

  const [scrollLock, setScrollLock] = useState(false);
  const [imageArray, setImageArray] = useState<string[]>(imageUrls || []);
  const [elementsArray, setElementsArray] = useState<JSX.Element[]>(
    elements || []
  );

  const scrollDelay = imageUrls?.length ? 600 : 1500;
  const thumbnailTransformationUrl = !sizeInfo.underXl
    ? "c_fill,h_400,w_400"
    : "c_fill,h_1600,w_1600";
  const imageWidth = sizeInfo.underXl ? "min-w-full w-full" : "w-1/3";

  const getOffsetDivider = () => {
    if (imageUrls) {
      return sizeInfo.underXl ? 1 : 3;
    }
    return 1;
  };

  const offsetDivider = getOffsetDivider();

  const getCarousal = () =>
    document.getElementsByClassName("CarouselWrapper")[0] as HTMLElement;

  useEffect(() => {
    const scrollCarousel = () => {
      const carousel = getCarousal();
      carousel.scrollLeft = (carousel.offsetWidth / offsetDivider) * 2;
    };

    setTimeout(() => scrollCarousel(), 10);
    window.addEventListener("resize", scrollCarousel);

    return () => {
      window.removeEventListener("resize", scrollCarousel);
    };
  }, [offsetDivider]);

  const moveArray = (ahead: boolean, smooth: boolean) => {
    const carousel = getCarousal();
    const leftValue = carousel.clientWidth / offsetDivider;
    const left = ahead ? leftValue : -leftValue;

    carousel.scrollBy({
      left,
      behavior: smooth ? "smooth" : "auto",
    });
  };

  const moveBack = () => {
    const carousel = getCarousal();
    const isNearToEdge =
      carousel.scrollWidth - carousel.scrollLeft > carousel.offsetWidth * 1.33;

    if (isNearToEdge) {
      if (imageArray) {
        setImageArray([
          ...imageArray.splice(imageArray.length - 1, 1),
          ...imageArray,
        ]);
      }
      if (elementsArray) {
        setElementsArray([
          ...elementsArray.splice(elementsArray.length - 1, 1),
          ...elementsArray,
        ]);
      }
      moveArray(true, false);
    }
  };

  const moveAhead = () => {
    const carousel = getCarousal();
    const isNearToEdge =
      carousel.scrollWidth - carousel.scrollLeft < carousel.offsetWidth * 1.66;

    if (isNearToEdge) {
      if (imageArray) {
        setImageArray([
          ...imageArray.splice(1, 2),
          ...imageArray.slice(1, imageArray.length),
          ...imageArray.slice(0, 1),
        ]);
      }
      if (elementsArray) {
        setElementsArray([
          ...elementsArray.splice(1, 2),
          ...elementsArray.slice(1, elementsArray.length),
          ...elementsArray.slice(0, 1),
        ]);
      }

      moveArray(false, false);
    }
  };

  return (
    <motion.div
      className="flex flex-row w-full justify-center items-center relative"
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={sizeInfo.underMd ? fadeInAnimation : delayedFadeInAnimation}
    >
      <NavigateBeforeIcon
        htmlColor={
          sizeInfo.underXl || elementsArray.length ? "white" : colors.mano4
        }
        fontSize="large"
        className={classNames("cursor-pointer absolute left-0 m-3 xl:m-2", {
          "xl:relative": imageArray.length,
        })}
        onClick={() => {
          if (!scrollLock) {
            moveArray(false, true);
            setScrollLock(true);
            setTimeout(() => {
              moveBack();
              setScrollLock(false);
            }, scrollDelay - 1);
          }
        }}
      />
      <div
        className="flex CarouselWrapper overflow-x-scroll"
        style={{
          width: !sizeInfo.underXl && imageArray.length ? "1083px" : "",
          maxWidth: !sizeInfo.underXl && imageArray.length ? "1083px" : "",
        }}
      >
        {imageArray &&
          imageArray.map((src, i) => (
            <ImageWrapper
              src={src.replace(
                "{{transformation}}",
                thumbnailTransformationUrl
              )}
              alt={`${src}`}
              key={i}
              className={classNames(imageWidth, "px-3 object-cover")}
              disableAnimation
            />
          ))}
        {elementsArray &&
          elementsArray.map((element) => (
            <div className="min-w-full w-full">{element}</div>
          ))}
      </div>
      <NavigateNextIcon
        htmlColor={
          sizeInfo.underXl || elementsArray.length ? "white" : colors.mano4
        }
        fontSize="large"
        className={classNames("cursor-pointer absolute right-0 m-3 xl:m-2", {
          "xl:relative": imageArray.length,
        })}
        onClick={() => {
          if (!scrollLock) {
            moveArray(true, true);
            setScrollLock(true);
            setTimeout(() => {
              moveAhead();
              setScrollLock(false);
            }, scrollDelay - 1);
          }
        }}
      />
    </motion.div>
  );
};
