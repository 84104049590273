import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "i18n";

import { ScrollToTop } from "components";
import { routes } from "routes/routes";
import { getTitleName } from "utils/title";

import "styles/index.css";
import "styles/global.scss";

function App() {
  const { t } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    document.title = getTitleName(t, location);
  }, [location, i18n.language]);

  return (
    <div className="App">
      <ScrollToTop />
      <Routes>
        {routes.map(({ path, element }) => (
          <Route path={path} element={element} />
        ))}
      </Routes>
    </div>
  );
}

export default App;
