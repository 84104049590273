import { TFunction } from "i18next";
import { Location } from "react-router-dom";

export const getTitleName = (t: TFunction, location: Location) => {
  let apartment;
  let subpage;

  const subpages = location.pathname.split("/").filter((path) => path !== "");

  if (subpages[0]) {
    apartment = subpages[0] === "mano" ? "Manó" : "Travertin";
  }

  if (subpages.length > 1) {
    const subpageLocation = subpages[1];
    if (subpageLocation === "gallery") subpage = t("NAVIGATION.LINKS.GALLERY");
    if (subpageLocation === "prices") subpage = t("NAVIGATION.LINKS.PRICES");
    if (subpageLocation === "contact") subpage = t("NAVIGATION.LINKS.CONTACT");
  }

  return `${apartment ? apartment : ""} ${
    subpages.length > 1 ? `- ${subpage}` : ""
  } ${`${apartment || subpages.length > 1 ? "| " : ""}`}Egriapartman.hu`;
};
