import { FC } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

import { useScroll } from "components";
import { fadeInAnimation } from "constants/animate";
import {
  descriptionClass,
  inclinedTitleClass,
  titleClass,
} from "constants/classNames";
import { Apartment } from "models";

import ManoClasses from "./WelcomeSectionMano.module.scss";
import TravertinClasses from "./WelcomeSectionTravertin.module.scss";

interface WelcomeSectionProps {
  apartment: Apartment;
  inclinedTitle: string;
  title: string;
  description: string;
}

const WelcomeSection: FC<WelcomeSectionProps> = ({
  apartment,
  inclinedTitle,
  title,
  description,
}) => {
  const { t } = useTranslation();
  const { ref, controls } = useScroll(0.4);

  const classOptions = {
    MANO: ManoClasses,
    TRAVERTIN: TravertinClasses,
  };
  const classes = classOptions[apartment];

  return (
    <motion.div
      className={classNames(
        "w-full text-center text-white flex flex-col justify-center items-center relative bg-blend-multiply bg-no-repeat bg-cover bg-center WelcomeSection",
        classes.WelcomeSection
      )}
      ref={ref}
      initial="hidden"
      animate={controls}
    >
      <motion.div
        className="max-w-7xl w-full px-5 md:px-10"
        variants={fadeInAnimation}
      >
        <p className={inclinedTitleClass}>{t(inclinedTitle)}</p>
        <p className={titleClass}>{t(title)}</p>
        <p className={descriptionClass}>{t(description)}</p>
        <Link to={"/mano/contact"}>
          <button
            className={classNames(
              classes.BookNowButton,
              { "text-mano4": apartment === Apartment.MANO },
              { "text-travertin5": apartment === Apartment.TRAVERTIN },
              "uppercase font-semibold"
            )}
          >
            {t("NAVIGATION.BUTTON")}
          </button>
        </Link>
      </motion.div>
    </motion.div>
  );
};

export default WelcomeSection;
