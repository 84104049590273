export interface Colors {
  mano1: string;
  mano2: string;
  mano3: string;
  mano4: string;
  mano5: string;
  travertin1: string;
  travertin2: string;
  travertin3: string;
  travertin4: string;
  travertin5: string;
}

export const colors: Colors = {
  mano1: "#f7f7f7",
  mano2: "#eeeeee",
  mano3: "#929aab",
  mano4: "#212832",
  mano5: "#15191f",
  travertin1: "#f5f2ed",
  travertin2: "#fcecd0",
  travertin3: "#bfa87f",
  travertin4: "#79582f",
  travertin5: "#202020",
};
