import { FC } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Card, CardContent } from "@mui/material";
import classNames from "classnames";
import { motion } from "framer-motion";

import { useScroll, useWindowResize } from "components";
import { CardListItem } from "models";
import {
  fadeInListItemAnimation,
  fadeInListNoDelayAnimation,
} from "constants/animate";

interface CardListProps {
  elements: CardListItem[];
}

const CardList: FC<CardListProps> = ({ elements }) => {
  const { t } = useTranslation();
  const { ref, controls } = useScroll(0.15);
  const location = useLocation();
  const sizeInfo = useWindowResize();

  const isMano = location.pathname.includes("/mano");
  const isTravertin = location.pathname.includes("/travertin");
  const commonClasses = [
    { "text-mano5": isMano },
    { "text-travertin5": isTravertin },
  ];

  return (
    <motion.div
      className="flex flex-row flex-wrap justify-center max-w-7xl"
      variants={fadeInListNoDelayAnimation}
      ref={ref}
      initial={"hidden"}
      animate={controls}
    >
      {elements.map((element) => (
        <motion.div
          variants={fadeInListItemAnimation}
          className={"w-1/4 sm:w-1/5 mx-1.5 mb-5 sm:m-5 py-2 sm:py-5"}
          style={{ minWidth: sizeInfo.underSm ? "155px" : "200px" }}
        >
          <Card variant="outlined" className="h-full" key={element.title}>
            <CardContent className="flex flex-col items-center">
              {element.icon}
              <p
                className={classNames(
                  "text-lg text-mano5 mb-3",
                  ...commonClasses
                )}
              >
                {t(element.title)}
              </p>
              <p
                className={classNames(
                  "text-sm text-center text-mano5",
                  ...commonClasses
                )}
              >
                {t(element.description)}
              </p>
            </CardContent>
          </Card>
        </motion.div>
      ))}
    </motion.div>
  );
};

export default CardList;
