import HomeIcon from "@mui/icons-material/Home";
import CollectionsIcon from "@mui/icons-material/Collections";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";

import { NavigationLink } from "components";

export const MANO_NAVIGATION_LINKS: NavigationLink[] = [
  {
    route: "/mano",
    label: "NAVIGATION.LINKS.HOME",
    icon: <HomeIcon />,
  },
  {
    route: "/mano/gallery",
    label: "NAVIGATION.LINKS.GALLERY",
    icon: <CollectionsIcon />,
  },
  {
    route: "/mano/prices",
    label: "NAVIGATION.LINKS.PRICES",
    icon: <LocalOfferIcon />,
  },
  {
    route: "/mano/contact",
    label: "NAVIGATION.LINKS.CONTACT",
    icon: <ContactSupportIcon />,
  },
];

export const TRAVERTIN_NAVIGATION_LINKS: NavigationLink[] = [
  {
    route: "/travertin",
    label: "NAVIGATION.LINKS.HOME",
    icon: <HomeIcon />,
  },
  {
    route: "/travertin/gallery",
    label: "NAVIGATION.LINKS.GALLERY",
    icon: <CollectionsIcon />,
  },
  {
    route: "/travertin/prices",
    label: "NAVIGATION.LINKS.PRICES",
    icon: <LocalOfferIcon />,
  },
  {
    route: "/travertin/contact",
    label: "NAVIGATION.LINKS.CONTACT",
    icon: <ContactSupportIcon />,
  },
];

export const szepCardImageUrl =
  "https://res.cloudinary.com/disyx1lwa/image/upload/v1646436824/egriapartman/szepkartya_immgyb.png";
