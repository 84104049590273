import LocalParkingIcon from "@mui/icons-material/LocalParking";
import WifiIcon from "@mui/icons-material/Wifi";
import SmokeFreeIcon from "@mui/icons-material/SmokeFree";
import KitchenIcon from "@mui/icons-material/Kitchen";
import TvIcon from "@mui/icons-material/Tv";
import ShowerIcon from "@mui/icons-material/Shower";
import AirIcon from "@mui/icons-material/Air";
import LocalHotelIcon from "@mui/icons-material/LocalHotel";
import FireplaceIcon from "@mui/icons-material/Fireplace";
import BalconyIcon from "@mui/icons-material/Balcony";
import FlareIcon from "@mui/icons-material/Flare";

import { DetailedListColumn } from "models";
import { colors } from "./colors";
import { MANO_PRICES, OTHER_PRICES, TRAVERTIN_PRICES } from "./prices";

export const MANO_LIST_ITEMS = [
  {
    icon: (
      <LocalParkingIcon
        fontSize="large"
        className="mb-5"
        htmlColor={colors.mano4}
      />
    ),
    title: "COMMON.PARKING",
    description: "MANO.CARDLIST_ARTICLE.DESCRIPTIONS.PARKING",
  },
  {
    icon: (
      <WifiIcon fontSize="large" className="mb-5" htmlColor={colors.mano4} />
    ),
    title: "COMMON.INTERNET",
    description: "MANO.CARDLIST_ARTICLE.DESCRIPTIONS.INTERNET",
  },
  {
    icon: <TvIcon fontSize="large" className="mb-5" htmlColor={colors.mano4} />,
    title: "COMMON.TV",
    description: "MANO.CARDLIST_ARTICLE.DESCRIPTIONS.TV",
  },
  {
    icon: (
      <SmokeFreeIcon
        fontSize="large"
        className="mb-5"
        htmlColor={colors.mano4}
      />
    ),
    title: "COMMON.SMOKING",
    description: "MANO.CARDLIST_ARTICLE.DESCRIPTIONS.SMOKING",
  },
  {
    icon: (
      <LocalHotelIcon
        fontSize="large"
        className="mb-5"
        htmlColor={colors.mano4}
      />
    ),
    title: "COMMON.ROOMS",
    description: "MANO.CARDLIST_ARTICLE.DESCRIPTIONS.ROOMS",
  },
  {
    icon: (
      <KitchenIcon fontSize="large" className="mb-5" htmlColor={colors.mano4} />
    ),
    title: "COMMON.KITCHEN",
    description: "MANO.CARDLIST_ARTICLE.DESCRIPTIONS.KITCHEN",
  },
  {
    icon: (
      <ShowerIcon fontSize="large" className="mb-5" htmlColor={colors.mano4} />
    ),
    title: "COMMON.BATHROOM",
    description: "MANO.CARDLIST_ARTICLE.DESCRIPTIONS.BATHROOM",
  },
  {
    icon: (
      <AirIcon fontSize="large" className="mb-5" htmlColor={colors.mano4} />
    ),
    title: "COMMON.AIRCONDITIONER",
    description: "MANO.CARDLIST_ARTICLE.DESCRIPTIONS.AIRCONDITIONER",
  },
];

export const MANO_DETAILED_LIST_ELEMENTS: DetailedListColumn[] = [
  {
    title: "COMMON.DETAILED_LIST.HEADERS.SIGHTS",
    items: [
      {
        label: "COMMON.DETAILED_LIST.LABELS.DOWNTOWN",
        value: 5,
        value2: 2,
        unit: "COMMON.MINUTE",
      },
      {
        label: "COMMON.DETAILED_LIST.LABELS.BASILICA",
        value: 8,
        value2: 3,
        unit: "COMMON.MINUTE",
      },
      {
        label: "COMMON.DETAILED_LIST.LABELS.MUSEUM",
        value: 10,
        value2: 3,
        unit: "COMMON.MINUTE",
      },
      {
        label: "COMMON.DETAILED_LIST.LABELS.CASTLE",
        value: 18,
        value2: 5,
        unit: "COMMON.MINUTE",
      },
      {
        label: "COMMON.DETAILED_LIST.LABELS.SZEPASSZONYVOLGY",
        value: 20,
        value2: 4,
        unit: "COMMON.MINUTE",
      },
    ],
  },
  {
    title: "COMMON.DETAILED_LIST.HEADERS.LEISURE",
    items: [
      {
        label: "COMMON.DETAILED_LIST.LABELS.AGRIA_PLAZA",
        value: 6,
        value2: 1,
        unit: "COMMON.MINUTE",
      },
      {
        label: "COMMON.DETAILED_LIST.LABELS.FREI_CAFE",
        value: 6,
        value2: 1,
        unit: "COMMON.MINUTE",
      },
      {
        label: "COMMON.DETAILED_LIST.LABELS.BODEGA_BISTRO",
        value: 6,
        value2: 5,
        unit: "COMMON.MINUTE",
      },
      {
        label: "COMMON.DETAILED_LIST.LABELS.HBH_BEERHOUSE",
        value: 11,
        value2: 4,
        unit: "COMMON.MINUTE",
      },
      {
        label: "COMMON.DETAILED_LIST.LABELS.THERMAL_BATH",
        value: 11,
        value2: 4,
        unit: "COMMON.MINUTE",
      },
    ],
  },
];

export const MANO_PRICES_LIST_ELEMENTS = [
  {
    title: "COMMON.PRICELIST",
    items: [
      {
        label: "COMMON.PRICES.2PERSON",
        value: MANO_PRICES["2PERSON"],
        unit: "COMMON.HUFPERNIGHT",
      },
      {
        label: "COMMON.PRICES.3PERSON",
        value: MANO_PRICES["3PERSON"],
        unit: "COMMON.HUFPERNIGHT",
      },
      {
        label: "COMMON.PRICES.4PERSON",
        value: MANO_PRICES["4PERSON"],
        unit: "COMMON.HUFPERNIGHT",
      },
      {
        label: "COMMON.PRICES.5PERSON",
        value: MANO_PRICES["5PERSON"],
        unit: "COMMON.HUFPERNIGHT",
      },
    ],
  },
  {
    title: "COMMON.OTHER",
    items: [
      {
        label: "COMMON.TAX",
        value: OTHER_PRICES.TAX,
        unit: "COMMON.HUFPERNIGHT",
      },
      {
        label: "COMMON.USE_OF_AIRCONDITIONER",
        value: OTHER_PRICES.AIRCONDITIONER,
        unit: "COMMON.HUFPERROOM",
      },
      {
        label: "COMMON.PARKING",
        value: "",
        unit: "COMMON.PRICES.FREE_PARKING",
      },
    ],
  },
];

export const TRAVERTIN_LIST_ITEMS = [
  {
    icon: (
      <LocalParkingIcon
        fontSize="large"
        className="mb-5"
        htmlColor={colors.travertin5}
      />
    ),
    title: "COMMON.PARKING",
    description: "TRAVERTIN.CARDLIST_ARTICLE.DESCRIPTIONS.PARKING",
  },
  {
    icon: (
      <WifiIcon
        fontSize="large"
        className="mb-5"
        htmlColor={colors.travertin5}
      />
    ),
    title: "COMMON.INTERNET",
    description: "TRAVERTIN.CARDLIST_ARTICLE.DESCRIPTIONS.INTERNET",
  },
  {
    icon: (
      <TvIcon fontSize="large" className="mb-5" htmlColor={colors.travertin5} />
    ),
    title: "COMMON.TV",
    description: "TRAVERTIN.CARDLIST_ARTICLE.DESCRIPTIONS.TV",
  },
  {
    icon: (
      <FireplaceIcon
        fontSize="large"
        className="mb-5"
        htmlColor={colors.travertin5}
      />
    ),
    title: "COMMON.SAUNA",
    description: "TRAVERTIN.CARDLIST_ARTICLE.DESCRIPTIONS.SAUNA",
  },
  {
    icon: (
      <LocalHotelIcon
        fontSize="large"
        className="mb-5"
        htmlColor={colors.travertin5}
      />
    ),
    title: "COMMON.ROOMS",
    description: "TRAVERTIN.CARDLIST_ARTICLE.DESCRIPTIONS.ROOMS",
  },
  {
    icon: (
      <KitchenIcon
        fontSize="large"
        className="mb-5"
        htmlColor={colors.travertin5}
      />
    ),
    title: "COMMON.KITCHEN",
    description: "TRAVERTIN.CARDLIST_ARTICLE.DESCRIPTIONS.KITCHEN",
  },
  {
    icon: (
      <ShowerIcon
        fontSize="large"
        className="mb-5"
        htmlColor={colors.travertin5}
      />
    ),
    title: "COMMON.BATHROOM",
    description: "TRAVERTIN.CARDLIST_ARTICLE.DESCRIPTIONS.BATHROOM",
  },
  {
    icon: (
      <BalconyIcon
        fontSize="large"
        className="mb-5"
        htmlColor={colors.travertin5}
      />
    ),
    title: "COMMON.BALCONY",
    description: "TRAVERTIN.CARDLIST_ARTICLE.DESCRIPTIONS.BALCONY",
  },
  {
    icon: (
      <AirIcon
        fontSize="large"
        className="mb-5"
        htmlColor={colors.travertin5}
      />
    ),
    title: "COMMON.AIRCONDITIONER",
    description: "TRAVERTIN.CARDLIST_ARTICLE.DESCRIPTIONS.AIRCONDITIONER",
  },
  {
    icon: (
      <FlareIcon
        fontSize="large"
        className="mb-5"
        htmlColor={colors.travertin5}
      />
    ),
    title: "COMMON.HYGIENE",
    description: "TRAVERTIN.CARDLIST_ARTICLE.DESCRIPTIONS.HYGIENE",
  },
];

export const TRAVERTIN_DETAILED_LIST_ELEMENTS: DetailedListColumn[] = [
  {
    title: "COMMON.DETAILED_LIST.HEADERS.SIGHTS",
    items: [
      {
        label: "COMMON.DETAILED_LIST.LABELS.MINARET",
        value: 0.1,
        value2: 0.1,
        unit: "COMMON.KM",
      },
      {
        label: "COMMON.DETAILED_LIST.LABELS.KOPCSIK",
        value: 0.1,
        value2: 0.1,
        unit: "COMMON.KM",
      },
      {
        label: "COMMON.DETAILED_LIST.LABELS.CASTLE",
        value: 0.3,
        value2: 0.3,
        unit: "COMMON.KM",
      },
      {
        label: "COMMON.DETAILED_LIST.LABELS.LYCEUM",
        value: 0.6,
        value2: 0.6,
        unit: "COMMON.KM",
      },
      {
        label: "COMMON.DETAILED_LIST.LABELS.MUSEUM",
        value: 0.6,
        value2: 0.6,
        unit: "COMMON.KM",
      },
      {
        label: "COMMON.DETAILED_LIST.LABELS.SZEPASSZONYVOLGY",
        value: 2.1,
        value2: 2.1,
        unit: "COMMON.KM",
      },
    ],
  },
  {
    title: "COMMON.DETAILED_LIST.HEADERS.LEISURE",
    items: [
      {
        label: "COMMON.DETAILED_LIST.LABELS.MAMMAMIA",
        value: 0.1,
        value2: 0.1,
        unit: "COMMON.KM",
      },
      {
        label: "COMMON.DETAILED_LIST.LABELS.MACOK",
        value: 0.3,
        value2: 0.3,
        unit: "COMMON.KM",
      },
      {
        label: "COMMON.DETAILED_LIST.LABELS.ARTIST",
        value: 0.4,
        value2: 0.4,
        unit: "COMMON.KM",
      },
      {
        label: "COMMON.DETAILED_LIST.LABELS.DEPRESSO",
        value: 0.4,
        value2: 0.4,
        unit: "COMMON.KM",
      },
      {
        label: "COMMON.DETAILED_LIST.LABELS.HBH_BEERHOUSE",
        value: 0.4,
        value2: 0.4,
        unit: "COMMON.KM",
      },

      {
        label: "COMMON.DETAILED_LIST.LABELS.THERMAL_BATH",
        value: 6.4,
        value2: 6.4,
        unit: "COMMON.KM",
      },
    ],
  },
];

export const TRAVERTIN_PRICES_LIST_ELEMENTS = [
  {
    title: "COMMON.PRICELIST",
    items: [
      {
        label: "COMMON.PRICES.2PERSON",
        value: TRAVERTIN_PRICES["2PERSON"],
        unit: "COMMON.HUFPERNIGHT",
      },
      {
        label: "COMMON.PRICES.3PERSON",
        value: TRAVERTIN_PRICES["3PERSON"],
        unit: "COMMON.HUFPERNIGHT",
      },
      {
        label: "COMMON.PRICES.4PERSON",
        value: TRAVERTIN_PRICES["4PERSON"],
        unit: "COMMON.HUFPERNIGHT",
      },
      {
        label: "COMMON.PRICES.5PERSON",
        value: TRAVERTIN_PRICES["5PERSON"],
        unit: "COMMON.HUFPERNIGHT",
      },
      {
        label: "COMMON.PRICES.6PERSON",
        value: TRAVERTIN_PRICES["6PERSON"],
        unit: "COMMON.HUFPERNIGHT",
      },
    ],
  },
  {
    title: "COMMON.OTHER",
    items: [
      {
        label: "COMMON.TAX",
        value: OTHER_PRICES.TAX,
        unit: "COMMON.HUFPERNIGHT",
      },
      {
        label: "COMMON.PARKING",
        value: "",
        unit: "COMMON.PRICES.FREE_PARKING_GARAGE",
      },
    ],
  },
];
