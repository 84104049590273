import { FC, useState } from "react";
import classNames from "classnames";
import { motion } from "framer-motion";

import { AppLoader, useScroll } from "components";
import { delayedFadeInAnimation } from "constants/animate";

interface ImageWrapperProps
  extends Pick<
    React.DetailedHTMLProps<
      React.ImgHTMLAttributes<HTMLImageElement>,
      HTMLImageElement
    >,
    "src" | "alt" | "className" | "width" | "style" | "onClick" | "loading"
  > {
  backgroundClass?: string;
  disableAnimation?: boolean;
}

const ImageWrapper: FC<ImageWrapperProps> = (props) => {
  const { ref, controls } = useScroll(0.1);
  const [isLoading, setLoading] = useState(true);

  const { backgroundClass, disableAnimation, ...imageProps } = props;

  return (
    <>
      <motion.img
        {...imageProps}
        ref={ref}
        animate={controls}
        initial={"hidden"}
        variants={disableAnimation ? undefined : delayedFadeInAnimation}
        alt={imageProps.alt || ""}
        className={classNames(
          imageProps.className,
          backgroundClass || "white",
          "bg-blend-multiply"
        )}
        onLoad={() => setLoading(false)}
      />
      <img src="" alt="" />
      {isLoading && <AppLoader />}
    </>
  );
};

export default ImageWrapper;
