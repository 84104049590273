import { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { TFunctionResult } from "i18next";
import PageviewIcon from "@mui/icons-material/Pageview";
import classNames from "classnames";
import { motion } from "framer-motion";

import {
  AppLoader,
  Article,
  Footer,
  ImageWrapper,
  NavigationBar,
  useScroll,
  useWindowResize,
} from "components";
import { delayedFadeInAnimation } from "constants/animate";
import { colors } from "constants/colors";
import { manoOutsideUrl, travertinOutsideUrl } from "constants/imageLists";
import { descriptionClass } from "constants/classNames";
import { ArticleVariant } from "models";

import "./ApartmentSelector.overrides.scss";

type SelectOptionProps = {
  href: string;
  backgroundImageUrl: string;
  label: TFunctionResult;
};

const FAKE_LOADING_TIME = 750;

const ApartmentSelector: FC = () => {
  const { t } = useTranslation();
  const { ref, controls } = useScroll(0.1);
  const sizeInfo = useWindowResize();

  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, FAKE_LOADING_TIME);
  }, []);

  const thumbnailTransformationUrl = "c_fill,h_400,w_400";

  const renderSelectOption = (props: SelectOptionProps) => {
    const { href, backgroundImageUrl, label } = props;

    return (
      <motion.div
        ref={ref}
        initial={"hidden"}
        animate={controls}
        variants={delayedFadeInAnimation}
      >
        <Link to={href} className="flex flex-col text-center m-7 items-center">
          <div className="ApartmentSelectOption relative">
            <ImageWrapper
              className="object-cover border-2 border-white"
              src={backgroundImageUrl}
              style={{
                width: sizeInfo.underMd ? 200 : 300,
                height: sizeInfo.underMd ? 200 : 300,
              }}
              disableAnimation
            />
            <div
              className={
                "ImageOverlay w-full absolute bottom-0 left-0 flex justify-center items-center"
              }
              style={{ backgroundColor: "rgba(0,0,0,0.35)" }}
            >
              <PageviewIcon htmlColor={colors.mano2} sx={{ fontSize: 65 }} />
            </div>
          </div>

          <p className={classNames("mt-5", descriptionClass)}>{label}</p>
        </Link>
      </motion.div>
    );
  };

  return (
    <motion.div
      className="flex flex-col min-h-screen w-screen bg-mano4 overflow-hidden ApartmentSelector bg-blend-multiply bg-no-repeat bg-cover bg-center"
      ref={ref}
      initial={"hidden"}
      animate={controls}
    >
      {isLoading ? (
        <AppLoader />
      ) : (
        <>
          <NavigationBar logoText="Egriapartman.hu" links={[]} />
          <div className="ApartmentSelectionsContainer w-full flex justify-center items-center">
            <Article
              title={t("APARTMENT_SELECTOR.TITLE")}
              description={t("APARTMENT_SELECTOR.DESCRIPTION")}
              darkBackground
              className="w-full flex h-full mb-0 items-center justify-center mt-5 md:mt-0"
              variant={ArticleVariant.HIGHLIGHT}
            >
              <div className="flex flex-col md:flex-row w-full justify-center text-white mt-10">
                {renderSelectOption({
                  href: "/travertin",
                  backgroundImageUrl: travertinOutsideUrl.replace(
                    "{{transformation}}",
                    thumbnailTransformationUrl
                  ),
                  label: t("TRAVERTIN.NAME"),
                })}
                {renderSelectOption({
                  href: "/mano",
                  backgroundImageUrl: manoOutsideUrl.replace(
                    "{{transformation}}",
                    thumbnailTransformationUrl
                  ),
                  label: t("MANO.NAME"),
                })}
              </div>
            </Article>
          </div>
          <Footer
            apartment={undefined}
            compactView
            logoText={t("MANO.NAME")}
            links={[]}
            email={"teodora.erdei@gmail.com"}
            telephone={"+36 (30) 269-8807"}
            address={"HU 3330, EGER Vörösmarty Mihály utca 75."}
          />
        </>
      )}
    </motion.div>
  );
};

export default ApartmentSelector;
