import { FC } from "react";

import { Article, ImageListWrapper, PageWrapper } from "components";
import { Apartment, ImageListItemData } from "models";

interface GalleryPageProps {
  apartment: Apartment;
  imageList: ImageListItemData[];
}

export const GalleryPage: FC<GalleryPageProps> = ({ apartment, imageList }) => {
  return (
    <PageWrapper apartment={apartment} hasContactArticle>
      <Article
        inclinedTitle={"COMMON.GALLERY"}
        title={"COMMON.OUR_APARTMENTS"}
        className={"mt-24 mb-0"}
      />
      <ImageListWrapper itemData={imageList} className={"mb-24"} />
    </PageWrapper>
  );
};
