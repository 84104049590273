import { FC, useState, useEffect, useCallback } from "react";
import classNames from "classnames";
import Modal from "@mui/material/Modal";
import { Box, ImageList, ImageListItem } from "@mui/material";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import CloseIcon from "@mui/icons-material/Close";
import disableScroll from "disable-scroll";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { motion } from "framer-motion";

import { ImageWrapper, useWindowResize, useScroll } from "components";
import {
  fadeInListAnimation,
  fadeInListItemShortAnimation,
} from "constants/animate";
import { ImageListItemData } from "models";

import classes from "./ImageListWrapper.module.scss";
import "./ImageListWrapper.overrides.scss";

export interface ImageListProps {
  itemData: ImageListItemData[];
  className?: string;
}

const ImageListWrapper: FC<ImageListProps> = ({ itemData, className }) => {
  const sizeInfo = useWindowResize();
  const { ref, controls } = useScroll(sizeInfo.underMd ? 0 : 0.1);

  const [isOpen, setOpen] = useState<boolean>(false);
  const [imageIndex, setImageIndex] = useState<number>(0);

  const thumbnailTransformationUrl = "c_fill,h_400,w_400";

  useEffect(() => {
    if (isOpen) {
      disableScroll.on();
      return;
    }
    disableScroll.off();

    return () => {
      disableScroll.off();
    };
  }, [isOpen]);

  const getCols = () => {
    if (sizeInfo.underSm) return 1;
    if (sizeInfo.underLg) return 2;
    if (sizeInfo.xl || sizeInfo.lg) return 3;
    return 4;
  };

  return (
    <motion.div
      ref={ref}
      animate={controls}
      variants={fadeInListAnimation}
      initial={"hidden"}
      className={
        "w-full min-h-content relative flex justify-center max-w-7xl py-10 px-5 md:px-10"
      }
    >
      <ImageList
        style={{ gap: "16px" }}
        className={classNames(
          className,
          classes.ImageListWrapper,
          "w-full h-fit min-h-fit"
        )}
        cols={getCols()}
        rowHeight={sizeInfo.underMd ? 300 : 350}
      >
        {itemData.map((item, index) => (
          <motion.div
            variants={fadeInListItemShortAnimation}
            className={"overflow-hidden relative flex justify-center"}
          >
            <Box sx={{ height: sizeInfo.underMd ? 300 : 350 }} key={index}>
              <ImageListItem
                key={item.src}
                className={"ImageListItem cursor-pointer"}
                onClick={() => {
                  setOpen(true);
                  setImageIndex(index);
                }}
              >
                <ImageWrapper
                  src={`${item.src.replace(
                    "{{transformation}}",
                    thumbnailTransformationUrl
                  )}`}
                  style={{ maxWidth: "400px" }}
                  alt={item.title}
                  loading="eager"
                />
                <div
                  className={
                    "ImageOverlay w-full absolute bottom-0 left-0 flex justify-center items-center"
                  }
                  style={{ backgroundColor: "rgba(0,0,0,0.35)" }}
                >
                  <ZoomInIcon htmlColor={"white"} sx={{ fontSize: 65 }} />
                </div>
              </ImageListItem>
            </Box>
          </motion.div>
        ))}
      </ImageList>
      <Modal
        open={isOpen}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="w-full h-full flex justify-center items-center">
          <CloseIcon
            htmlColor="white"
            fontSize="large"
            className="absolute top-0 right-0 m-5 cursor-pointer"
            onClick={() => setOpen(false)}
          />
          {imageIndex > 0 && (
            <NavigateBeforeIcon
              htmlColor={"white"}
              fontSize="large"
              className={classNames(
                "cursor-pointer absolute left-0 m-3 xl:m-2"
              )}
              onClick={(e) => {
                e.stopPropagation();
                setImageIndex(imageIndex - 1);
              }}
            />
          )}

          <ImageWrapper
            className="object-contain cursor-default"
            style={{ maxHeight: "75%", maxWidth: "75%" }}
            src={`${itemData[imageIndex].src.replace(
              "{{transformation}}",
              ""
            )}`}
            alt={itemData[imageIndex].title}
            loading="eager"
            onClick={(e) => e.stopPropagation()}
          />
          {imageIndex < itemData.length - 1 && (
            <NavigateNextIcon
              htmlColor={"white"}
              fontSize="large"
              className={classNames(
                "cursor-pointer absolute right-0 m-3 xl:m-2"
              )}
              onClick={(e) => {
                e.stopPropagation();
                setImageIndex(imageIndex + 1);
              }}
            />
          )}
        </Box>
      </Modal>
    </motion.div>
  );
};

export default ImageListWrapper;
