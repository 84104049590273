import { FC } from "react";

import {
  Article,
  CardList,
  CarouselWrapper,
  DetailedList,
  WelcomeSection,
  useWindowResize,
  PageWrapper,
} from "components";
import {
  MANO_DETAILED_LIST_ELEMENTS,
  MANO_LIST_ITEMS,
} from "constants/listItems";
import {
  MANO_GRAPE_PHOTO,
  MANO_HOMEPAGE_CAROUSAL_IMAGES,
} from "constants/imageLists";

import "./HomePage.overrides.scss";
import { Apartment, ArticleVariant } from "models";

const ManoHomePage: FC = () => {
  const sizeInfo = useWindowResize();

  return (
    <PageWrapper
      apartment={Apartment.MANO}
      useTransparentNavbar
      hasContactArticle
    >
      <WelcomeSection
        apartment={Apartment.MANO}
        inclinedTitle={"MANO.WELCOME_SECTION.INCLINED"}
        title={"MANO.WELCOME_SECTION.TITLE"}
        description={"MANO.WELCOME_SECTION.DESCRIPTION"}
      />
      <Article
        className={"IntroductionArticle"}
        inclinedTitle={"COMMON.OUR_APARTMENTS"}
        title={"MANO.INTRO_ARTICLE.TITLE"}
        description={"MANO.INTRO_ARTICLE.DESCRIPTION"}
        link={{ label: "NAVIGATION.LINKS.GALLERY", href: "/mano/gallery" }}
        children={<CarouselWrapper imageUrls={MANO_HOMEPAGE_CAROUSAL_IMAGES} />}
      />
      <Article
        inclinedTitle={"MANO.CARDLIST_ARTICLE.INCLINED"}
        title={"COMMON.SERVICES"}
        children={<CardList elements={MANO_LIST_ITEMS} />}
      />
      <Article
        variant={ArticleVariant.HIGHLIGHT}
        inclinedTitle={"MANO.PROMOTION_ARTICLE.INCLINED"}
        title={"MANO.PROMOTION_ARTICLE.TITLE"}
        description={"MANO.PROMOTION_ARTICLE.DESCRIPTION"}
        link={{ label: "COMMON.VIEW", href: "/mano/prices" }}
        className={"bg-mano4 py-20 w-full text-white"}
        darkBackground
      />
      <Article
        inclinedTitle={"COMMON.EGER.INCLINED"}
        title={"COMMON.EGER.TITLE"}
        description={"COMMON.EGER.DESCRIPTION"}
        descriptionClassName={"text-justify"}
        imageSrc={MANO_GRAPE_PHOTO}
        inlineImage={!sizeInfo.underXl}
      />
      <DetailedList elements={MANO_DETAILED_LIST_ELEMENTS} hasToggleButtons />
    </PageWrapper>
  );
};

export default ManoHomePage;
